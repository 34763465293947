import { useReducer, createContext, FunctionComponent } from "react";
import * as React from "react";
import { Alternative } from "@lysaab/ui-2";
import { InvestmentAccountId } from "../../../../data/dataAccounts";

export enum TypeOfTransfer {
  FULL = 0,
  PARTIAL = 1,
}

export interface IskTransferState {
  institution?: Alternative<string>;
  accountNumber?: string;
  account?: Alternative<InvestmentAccountId>;
  type?: Alternative<TypeOfTransfer>;
  amount?: string;
  acceptedTerms?: Alternative<boolean>;
  transferId?: number;
  closeAccount: boolean;
  office?: string;
}

export interface IskTransferContextProps {
  state: IskTransferState;
  setState: (newState: Partial<IskTransferState>) => void;
}

export interface withIskTransferProps {
  isktransfer: IskTransferContextProps;
}

export const IskTransferContext = createContext<IskTransferContextProps>(
  {} as IskTransferContextProps
);

function stateReducer(
  state: IskTransferState,
  newState: Partial<IskTransferState>
) {
  return { ...state, ...newState };
}

export const IskTransferContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, { closeAccount: false });

  return (
    <IskTransferContext.Provider value={{ state, setState }}>
      {children}
    </IskTransferContext.Provider>
  );
};
