import { defineMessages, useIntl } from "react-intl";
import { useStoryValues } from "../../../../hooks/useStoryValues";
import { useSafeNavigation } from "../../../../hooks/useSafeNavigation";
import { Route, generatePath, useHistory, useLocation } from "react-router";
import { PageStripped } from "../../../../pages/PageStripped";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../../pages/overview/OverviewPage";
import { Story } from "@lysaab/ui-2";
import { Switch } from "../../../../components/route/Switch";
import { memo, FunctionComponent, useEffect } from "react";
import { Sustainability } from "./sustainability/Sustainability";
import { Preference } from "./sustainabilityPreference/SuitabilityPreference";
import {
  DEFAULT_PAYOUT_AGE,
  PAYOUT_PLAN_THRESHOLD_AGE,
  TransferContextProvider,
  useTransfer,
} from "./TransferContext";
import {
  GetPensionSuitabilityAssessmentRequest,
  SustainabilityImportance,
  dataInvestments,
  getPensionAccountQuestions,
  isSustainabilityImportantSpecific,
} from "../../../../data/dataInvestments";
import { SustainabilityImportantQuestions } from "./sustainabilityImportantQuestions/SustainabilityImportantQuestions";
import { useLanguage } from "../../../../context/LocalizationContext";
import { ConfirmEsgUpdateWrapper } from "./confirmEsgUpdateWrapper/ConfirmEsgUpdateWrapper";
import { Intro } from "./intro/Intro";
import { InsuranceInformation } from "./insuranceInformation/InsuranceInformation";
import { InsuranceInformationSummary } from "./insuranceInformationSummary/InsuranceInformationSummary";
import { Advice } from "./advice/Advice";
import { Confirm } from "./confirm/Confirm";
import { RiskDeviation } from "./riskDeviation/RiskDeviation";
import { Done } from "./done/Done";
import { GroupIntroInsuranceInfo } from "./groupIntroInsuranceInfo/GroupIntroInsuranceInfo";
import { GroupIntroFindPensions } from "./groupIntroFindPensions/GroupIntroFindPensions";
import { Sign } from "./sign/Sign";
import { SnailMail } from "./snailMail/SnailMail";
import { Fees } from "./fees/Fees";
import { BankID } from "./bankID/BankID";
import { PensionMoveSigningOptions } from "../../../../data/dataLifePensionMove";
import { useUser } from "../../../../context/UserContext";
import { getUserAge } from "./utils/userAge";
import { PayoutPlan } from "./payoutPlan/PayoutPlan";
import { CollectionMethods } from "./collectionMethods/CollectionMethods";
import { InsuranceSigningList } from "./insuranceCompanySigningList/InsuranceSigningList";
import { Footer } from "./components/footer/Footer";

export const TRANSFER_PENSIONS_URL = "/transfer-pensions";

const messages = defineMessages({
  header: { id: "sweden.transfer-pensions.header" },
  ariaProgressLabel: {
    id: "sweden.transfer-pensions.ariaProgressLabel",
  },
});

const InnerTransfersPensionsStory: FunctionComponent = memo(() => {
  const [transfer, setTransfer] = useTransfer();

  const DYNAMIC_ROUTES = {
    INSURANCE_INFORMATION: `${TRANSFER_PENSIONS_URL}/insurance-information/:id`,
  };

  const dynamicRoutes: {} = transfer.moves.reduce((acc, curr, idx) => {
    return {
      ...acc,
      [`INSURANCE_INFORMATION_${idx}`]: `${TRANSFER_PENSIONS_URL}/insurance-information/${idx}`,
    };
  }, {});

  const ACTUAL_ROUTES = {
    INTRO: `${TRANSFER_PENSIONS_URL}/`,
    GROUP_INTRO_FIND: `${TRANSFER_PENSIONS_URL}/group-intro-find`,
    COLLECTION_METHODS: `${TRANSFER_PENSIONS_URL}/collection-methods`,
    INSURANCE_SIGNING_LIST: `${TRANSFER_PENSIONS_URL}/insurance-signing-list`,
    INSURANCES: `${TRANSFER_PENSIONS_URL}/insurances/`,
    ...dynamicRoutes,
    INSURANCE_INFORMATION_SUMMARY: `${TRANSFER_PENSIONS_URL}/insurance-information-summary`,
    GROUP_INTRO_INSURANCE: `${TRANSFER_PENSIONS_URL}/group-intro-insurance`,
    PAYOUT_PLAN: `${TRANSFER_PENSIONS_URL}/payout-plan`,
    SUSTAINABILITY: `${TRANSFER_PENSIONS_URL}/sustainability`,
    PREFERENCE: `${TRANSFER_PENSIONS_URL}/preference`,
    SUSTAINABILITY_QUESTIONS: `${TRANSFER_PENSIONS_URL}/sustainability-questions`,
    CONFIRM_ESG_UPDATE: `${TRANSFER_PENSIONS_URL}/confirm-esg-update`,
    ADVICE: `${TRANSFER_PENSIONS_URL}/advice`,
    RISK_DEVIATION: `${TRANSFER_PENSIONS_URL}/risk-deviation`,
    FEES: `${TRANSFER_PENSIONS_URL}/fees`,
    REPAYMENT: `${TRANSFER_PENSIONS_URL}/repayment`,
    CONFIRM: `${TRANSFER_PENSIONS_URL}/confirm`,
    SIGN: `${TRANSFER_PENSIONS_URL}/sign/:caseId`,
    SIGN_SNAIL_MAIL: `${TRANSFER_PENSIONS_URL}/snail-mail/:caseId`,
    SIGN_BANK_ID: `${TRANSFER_PENSIONS_URL}/bank-id/:caseId`,
    DONE: `${TRANSFER_PENSIONS_URL}/done/:caseId`,
  };

  const language = useLanguage();
  const history = useHistory();
  const location = useLocation();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(ACTUAL_ROUTES);
  const user = useUser();
  const age = getUserAge(user.tin);
  const moves = transfer.moves;

  const onBack = () => {
    history.goBack();
  };

  /**
   * Align withdrawalAge with actual age in case user is older than default payout age.
   */
  useEffect(() => {
    if (age && age > DEFAULT_PAYOUT_AGE) {
      setTransfer({ withdrawalAge: age });
    }
  }, [age, setTransfer]);

  // TODO_PEN: transfer is currently created with a default caseId, which needs to be overridden on every new case. Should probably start out as undefined.
  useEffect(() => {
    setTransfer({ caseId: crypto.randomUUID() });
  }, [setTransfer]);

  return (
    <PageStripped>
      <div className="transfer-pension-story">
        <Story
          ariaLabelProgress={() =>
            intl.formatMessage(messages.ariaProgressLabel, {
              current: currentIndex + 1,
              total: storyLength,
            })
          }
          header={intl.formatMessage(messages.header)}
          progress={
            // This works around a bug in the Story component where it renders 0 as a string,
            // we should fix
            storyProgress === 0 ? undefined : storyProgress
          }
          showBack={
            currentIndex > 0 && currentIndex < Object.values(ROUTES).length - 2
          }
          onBack={onBack}
          transitionKey={currentIndex.toString()}
          showClose={true}
          onExit={() => {
            safeNavigation(getNavLink(OVERVIEW_PAGE_URL));
          }}
        >
          <Switch
            location={location}
            {...{
              order: currentIndex,
            }}
          >
            <Route exact path={ROUTES.INTRO}>
              <Intro
                next={() => safeNavigation(ROUTES.GROUP_INTRO_FIND)}
                exit={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
              />
              <Footer />
            </Route>
            <Route exact path={ROUTES.GROUP_INTRO_FIND}>
              <GroupIntroFindPensions
                next={() => safeNavigation(ROUTES.COLLECTION_METHODS)}
              />
              <Footer />
            </Route>
            <Route exact path={ROUTES.COLLECTION_METHODS}>
              <CollectionMethods
                next={() => safeNavigation(ROUTES.INSURANCE_SIGNING_LIST)}
                toManualMethod={() => {
                  if (transfer?.moves?.length === 0) {
                    setTransfer({ moves: [{}] });
                  }
                  safeNavigation(
                    generatePath(
                      getNavLink(DYNAMIC_ROUTES.INSURANCE_INFORMATION),
                      { id: 0 }
                    )
                  );
                }}
              />
              <Footer />
            </Route>
            <Route exact path={ROUTES.INSURANCE_SIGNING_LIST}>
              <InsuranceSigningList
                next={() =>
                  safeNavigation(ROUTES.INSURANCE_INFORMATION_SUMMARY)
                }
                reset={() => {
                  safeNavigation(ROUTES.COLLECTION_METHODS);
                }}
              />
              <Footer />
            </Route>
            {moves.map((_move, idx) => {
              return (
                <Route
                  exact
                  path={generatePath(
                    getNavLink(DYNAMIC_ROUTES.INSURANCE_INFORMATION),
                    { id: idx }
                  )}
                  key={idx}
                >
                  <InsuranceInformation
                    next={() => {
                      if (idx === moves.length - 1) {
                        safeNavigation(ROUTES.GROUP_INTRO_INSURANCE);
                      } else {
                        safeNavigation(
                          generatePath(
                            getNavLink(DYNAMIC_ROUTES.INSURANCE_INFORMATION),
                            {
                              id: idx + 1,
                            }
                          )
                        );
                      }
                    }}
                    navigateToAdditionalMove={() =>
                      safeNavigation(
                        generatePath(
                          getNavLink(DYNAMIC_ROUTES.INSURANCE_INFORMATION),
                          {
                            id: idx + 1,
                          }
                        )
                      )
                    }
                    reset={() => {
                      safeNavigation(ROUTES.GROUP_INTRO_FIND);
                    }}
                  />
                  <Footer />
                </Route>
              );
            })}
            <Route path={ROUTES.INSURANCE_INFORMATION_SUMMARY}>
              <InsuranceInformationSummary
                next={() => safeNavigation(ROUTES.GROUP_INTRO_INSURANCE)}
                goBack={() => {
                  safeNavigation(ROUTES.COLLECTION_METHODS);
                }}
                toManualMethod={() => {
                  if (transfer?.moves?.length === 0) {
                    setTransfer({ moves: [{}] });
                  }
                  safeNavigation(
                    generatePath(
                      getNavLink(DYNAMIC_ROUTES.INSURANCE_INFORMATION),
                      { id: 0 }
                    )
                  );
                }}
              />
              <Footer />
            </Route>
            <Route path={ROUTES.GROUP_INTRO_INSURANCE}>
              <GroupIntroInsuranceInfo
                next={() => {
                  if (age && age >= PAYOUT_PLAN_THRESHOLD_AGE) {
                    safeNavigation(ROUTES.PAYOUT_PLAN);
                  } else {
                    safeNavigation(ROUTES.SUSTAINABILITY);
                  }
                }}
              />
              <Footer />
            </Route>
            <Route path={ROUTES.PAYOUT_PLAN}>
              <PayoutPlan next={() => safeNavigation(ROUTES.SUSTAINABILITY)} />
              <Footer />
            </Route>
            <Route exact path={ROUTES.SUSTAINABILITY}>
              <Sustainability
                next={() => {
                  if (
                    transfer.sustainability ===
                    SustainabilityImportance.IMPORTANT
                  ) {
                    safeNavigation(ROUTES.PREFERENCE);
                  } else {
                    safeNavigation(ROUTES.ADVICE);
                  }
                }}
              />
              <Footer />
            </Route>
            <Route exact path={ROUTES.PREFERENCE}>
              <Preference
                next={() => {
                  if (isSustainabilityImportantSpecific(transfer)) {
                    safeNavigation(ROUTES.SUSTAINABILITY_QUESTIONS);
                  } else {
                    safeNavigation(ROUTES.ADVICE);
                  }
                }}
              />
              <Footer />
            </Route>
            <Route exact path={ROUTES.SUSTAINABILITY_QUESTIONS}>
              <SustainabilityImportantQuestions
                next={() => {
                  const data: Partial<GetPensionSuitabilityAssessmentRequest> =
                    {
                      language: language,
                      age: age,
                      ...getPensionAccountQuestions(transfer),
                    };

                  dataInvestments
                    .getNewPensionAccountSuitability(data)
                    .then((advise) => {
                      if (advise.esgResult.esgBestMatch) {
                        safeNavigation(ROUTES.CONFIRM_ESG_UPDATE);
                      } else {
                        safeNavigation(ROUTES.ADVICE);
                      }
                    });
                }}
              />
              <Footer />
            </Route>
            <Route exact path={ROUTES.CONFIRM_ESG_UPDATE}>
              <ConfirmEsgUpdateWrapper
                next={() => safeNavigation(ROUTES.ADVICE)}
              />
            </Route>
            <Route exact path={ROUTES.ADVICE}>
              <Advice
                next={() => safeNavigation(ROUTES.CONFIRM)}
                toRiskDeviation={() => safeNavigation(ROUTES.RISK_DEVIATION)}
                toFees={() => safeNavigation(ROUTES.FEES)}
              />
              <Footer />
            </Route>
            <Route exact path={ROUTES.RISK_DEVIATION}>
              <RiskDeviation />
              <Footer />
            </Route>
            <Route exact path={ROUTES.FEES}>
              <Fees next={() => safeNavigation(ROUTES.ADVICE)} />
              <Footer />
            </Route>
            <Route exact path={ROUTES.CONFIRM}>
              <Confirm
                next={(caseId) => {
                  safeNavigation(generatePath(ROUTES.SIGN, { caseId }));
                }}
              />
              <Footer />
            </Route>
            <Route exact path={ROUTES.SIGN}>
              <Sign
                next={(caseId) => {
                  if (
                    transfer.moves.find(
                      (move) =>
                        move.signing === PensionMoveSigningOptions.MANUAL ||
                        move.signing === PensionMoveSigningOptions.SCRIVE
                    )
                  ) {
                    safeNavigation(
                      generatePath(ROUTES.SIGN_SNAIL_MAIL, { caseId })
                    );
                  } else {
                    safeNavigation(
                      generatePath(ROUTES.SIGN_BANK_ID, { caseId })
                    );
                  }
                }}
              />
              <Footer />
            </Route>
            <Route exact path={ROUTES.SIGN_SNAIL_MAIL}>
              <SnailMail
                next={(caseId) => {
                  if (
                    transfer.moves.find(
                      (move) =>
                        move.signing === PensionMoveSigningOptions.BANKID
                    )
                  ) {
                    safeNavigation(
                      generatePath(ROUTES.SIGN_BANK_ID, { caseId })
                    );
                  } else {
                    safeNavigation(generatePath(ROUTES.DONE, { caseId }));
                  }
                }}
              />
              <Footer />
            </Route>
            <Route exact path={ROUTES.SIGN_BANK_ID}>
              <BankID
                next={(caseId) =>
                  safeNavigation(generatePath(ROUTES.DONE, { caseId }))
                }
              />
              <Footer />
            </Route>
            <Route exact path={ROUTES.DONE}>
              <Done
                next={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
              />
              <Footer />
            </Route>
            <Route>
              <p>Whoops - page not found</p>
            </Route>
          </Switch>
        </Story>
      </div>
    </PageStripped>
  );
});

export const TransfersPensionsStory: FunctionComponent = memo(() => {
  return (
    <TransferContextProvider>
      <InnerTransfersPensionsStory />
    </TransferContextProvider>
  );
});
