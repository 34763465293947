import { useContext } from "react";
import * as React from "react";
import { LayoutContext } from "../../context/LayoutContext";
import { useIsSignedIn } from "../../hooks/useIsSignedIn";
import { SeeNoEvilBar } from "./bars/SeeNoEvilBar";

export const EventBars: React.VFC = () => {
  const layoutContext = useContext(LayoutContext);

  const isSignedIn = useIsSignedIn();

  if (!isSignedIn) {
    return null;
  }

  return (
    <div className="event-bars">
      {layoutContext.state.seeNoEvil && <SeeNoEvilBar />}
    </div>
  );
};
