import * as React from "react";
import "./EventBar.scss";
import { Button, Icon } from "@lysaab/ui-2";
import { TransparentButton } from "../transparentButton/TransparentButton";

export interface Props {
  action: () => void;
  actionText: string;
  headerText: string | JSX.Element;
  onClose?: () => void;
}

export const EventBar: React.VFC<Props> = ({
  action,
  actionText,
  headerText,
  onClose,
}) => {
  return (
    <div className="event-bar">
      <div className="content-wrapper">
        {headerText}
        <div className="right-wrapper">
          <div>
            <Button size="small" onClick={action} label={actionText} />
          </div>
          <TransparentButton
            onClick={() => onClose && onClose()}
            className="close-button"
          >
            <Icon.Close />
          </TransparentButton>
        </div>
      </div>
    </div>
  );
};
