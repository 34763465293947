import { useEffect, useRef, VoidFunctionComponent } from "react";

import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import { useTransfer } from "../TransferContext";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { Button, Form, LysaFormRef, Typography } from "@lysaab/ui-2";

import { SustainabilityQuestion } from "../../../../../pageComponents/sustainability/SustainabilityQuestion";

import "./Sustainability.scss";

interface Props {
  next(): void;
}

export const Sustainability: VoidFunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const [transfer, setTransfer] = useTransfer();
  const history = useHistory();

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (formRef.current?.isValid) {
          next();
        }
      }}
    >
      <SustainabilityQuestion
        sustainability={transfer.sustainability}
        setSustainability={(sustainability) => setTransfer({ sustainability })}
      />
      <Typography
        type="body"
        className="transfer-pension-sustainability-footer"
      >
        <FormattedMessage id="sweden.transfer-pension.sustainability.footer" />
      </Typography>
      <Button
        className="transfer-pension-sustainability-button"
        block
        type="submit"
        label={
          <FormattedMessage id="sweden.transfer-pension.sustainability.button" />
        }
      />
    </Form>
  );
};
