import { useCallback } from "react";

import * as H from "history";
import { TranslatedText } from "../../../../components/TranslatedText";
import { AvailableBank } from "../../../../data/dataTink";
import { BankSelection } from "../../../../components/tink/BankSelection";

interface Props {
  next: () => void;
  missingBank?: H.LocationDescriptor<H.LocationState>;
}

export function TinkBankSelectionWrapper({ next, missingBank }: Props) {
  const onComplete = useCallback(
    (bank: AvailableBank) => {
      setTimeout(() => {
        next();
      }, 0);
    },
    [next]
  );

  return (
    <BankSelection
      onComplete={onComplete}
      missingBank={missingBank}
      /*TODO_TINK 
       * We filter some banks since we can't verify the owner of the account from these banks 
      filterBanksFunction={(bank: AvailableBank) =>
        bank.allowWithdrawalAccounts
      }
    */
      missingBankHeader={
        <TranslatedText id="klarna.bank-selection.missing-bank.header" />
      }
      missingBankDescription={
        <TranslatedText id="klarna.bank-selection.missing-bank.description" />
      }
    />
  );
}
