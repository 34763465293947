import { FunctionComponent, MutableRefObject, useRef, useState } from "react";
import {
  AllocationBarIntl,
  Button,
  Form,
  LysaFormRef,
  SNACKBAR_TYPES,
  Slider,
  Snackbar,
  Typography,
} from "@lysaab/ui-2";
import { useHistory } from "react-router-dom";
import { ROUTES } from "./UpdateAccountPage";
import { getNavLink } from "../../hooks/useCountryUrls";
import { TranslatedText } from "../../components/TranslatedText";
import { EditAccounrRiskParams } from "../../pageComponents/editAccountRiskConfirm/EditAccountRiskConfirm";
import { FormattedMessage, useIntl } from "react-intl";
import "./AllocationSelection.scss";
import AnimateHeight from "react-animate-height";
import { useAccount } from "../../hooks/useAccount";
import { AccountType, isInvestmentAccount } from "../../data/dataAccounts";
import { RiskSavingsHorizonValidator } from "../../components/validators/RiskSavingsHorizonValidator";
import { useAdviseAccount } from "../../hooks/useAdviseAccount";
import { isInvestmentAdviseAccount } from "../../data/dataInvestments";

interface Props {
  values: MutableRefObject<EditAccounrRiskParams | undefined>;
}

const MIN_KF_RECOMMENDED_ALLOCATION = 20;

export const AllocationSelection: FunctionComponent<Props> = ({ values }) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const history = useHistory();
  const account = useAccount(values.current?.accountId);
  const [advice] = useAdviseAccount(values.current?.accountId);

  const [risk, setRisk] = useState(
    typeof values.current?.newRisk !== "undefined"
      ? parseInt(values.current?.newRisk, 10)
      : typeof values.current?.takenRisk !== "undefined"
      ? parseInt(values.current?.takenRisk, 10)
      : 0
  );

  if (!values.current) {
    return null;
  }

  const advisedRisk = parseInt(values.current.advisedRisk, 10);
  const takenRisk = parseInt(values.current.takenRisk, 10);

  return (
    <div className="update-account__allocation-selection">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            formRef.current?.isValid &&
            typeof values.current !== "undefined"
          ) {
            values.current = {
              ...values.current,
              newRisk: risk.toString(),
            };

            if (Math.abs(risk - advisedRisk) > 15) {
              history.push(getNavLink(ROUTES.WARNING));
            } else {
              history.push(getNavLink(ROUTES.SUMMARY));
            }
          }
        }}
      >
        <Typography type="h2">
          <TranslatedText id="updateAccountPage.allocation.header" />
        </Typography>

        <section>
          <div>
            <strong>
              {intl.formatMessage({
                id: "updateAccountPage.allocation.advisedRisk.label",
              })}
            </strong>
            <AllocationBarIntl
              messages={{
                stocks: intl.formatMessage(
                  {
                    id: "updateAccountPage.allocation.advisedRisk.stocks",
                  },
                  {
                    stocks: intl.formatNumber(advisedRisk / 100, {
                      style: "percent",
                      maximumFractionDigits: 2,
                    }),
                  }
                ),
                bonds: intl.formatMessage(
                  {
                    id: "updateAccountPage.allocation.advisedRisk.bonds",
                  },
                  {
                    bonds: intl.formatNumber((100 - advisedRisk) / 100, {
                      style: "percent",
                      maximumFractionDigits: 2,
                    }),
                  }
                ),
              }}
              risk={advisedRisk}
              data-test-id="adviced-risk"
            />
          </div>

          <div>
            <strong>
              {intl.formatMessage({
                id: "updateAccountPage.allocation.takenRisk.label",
              })}
            </strong>
            <AllocationBarIntl
              messages={{
                stocks: intl.formatMessage(
                  {
                    id: "updateAccountPage.allocation.takenRisk.stocks",
                  },
                  {
                    stocks: intl.formatNumber(takenRisk / 100, {
                      style: "percent",
                      maximumFractionDigits: 2,
                    }),
                  }
                ),
                bonds: intl.formatMessage(
                  {
                    id: "updateAccountPage.allocation.takenRisk.bonds",
                  },
                  {
                    bonds: intl.formatNumber((100 - takenRisk) / 100, {
                      style: "percent",
                      maximumFractionDigits: 2,
                    }),
                  }
                ),
              }}
              risk={advisedRisk}
              data-test-id="adviced-risk"
            />
          </div>

          {advice && isInvestmentAdviseAccount(advice) && (
            <Slider
              label={
                <>
                  <strong>
                    <FormattedMessage id="updateAccountPage.allocation.newRisk.label" />
                  </strong>
                  <div className="allocation-slider-value-wrapper">
                    <span className="allocation-bar-name">
                      {intl.formatMessage(
                        {
                          id: "updateAccountPage.allocation.newRisk.stocks",
                        },
                        {
                          stocks: intl.formatNumber(risk / 100, {
                            style: "percent",
                          }),
                        }
                      )}
                    </span>
                    <span className="allocation-bar-name">
                      {intl.formatMessage(
                        {
                          id: "updateAccountPage.allocation.newRisk.bonds",
                        },
                        {
                          bonds: intl.formatNumber((100 - risk) / 100, {
                            style: "percent",
                          }),
                        }
                      )}
                    </span>
                  </div>
                </>
              }
              min={0}
              max={100}
              value={risk}
              onChange={setRisk}
              risk
              validators={[
                new RiskSavingsHorizonValidator(intl, advice?.savingsHorizon),
              ]}
            />
          )}

          <AnimateHeight
            height={
              isInvestmentAccount(account) &&
              [AccountType.KF_SWE, AccountType.DANICA_KF].includes(
                account.type
              ) &&
              risk < MIN_KF_RECOMMENDED_ALLOCATION
                ? "auto"
                : 0
            }
            animateOpacity={true}
          >
            <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
              <div>
                <TranslatedText
                  id="updateAccountPage.allocation.kf-risk.warning"
                  values={{ minRisk: MIN_KF_RECOMMENDED_ALLOCATION / 100 }}
                />
              </div>
            </Snackbar>
          </AnimateHeight>

          <Button
            label={intl.formatMessage({
              id: "updateAccountPage.allocation.cta.next",
            })}
            block
            type="submit"
          />
        </section>
      </Form>
    </div>
  );
};
