import { Button } from "@lysaab/ui-2";
import { useContext, useState } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import { SeeNoEvilModal } from "../seeNoEvilModal/SeeNoEvilModal";
import "./SeeNoEvilToggle.scss";

export const SeeNoEvilToggle: React.VFC = () => {
  const layoutContext = useContext(LayoutContext);
  const [showModal, setShowModal] = useState(false);

  const toggleSetNoEvilMode = () => {
    const seeNoEvil = !layoutContext.state.seeNoEvil;

    if (seeNoEvil) {
      setShowModal(true);
    } else {
      layoutContext.updateSeeNoEvil(false);
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    }
  };

  return (
    <div className="see-no-evil-toggle">
      <Button
        size="small"
        variant="secondary"
        onClick={toggleSetNoEvilMode}
        label="🙈"
      />
      <SeeNoEvilModal
        showModal={showModal}
        setShowModal={setShowModal}
        onAccept={() => layoutContext.updateSeeNoEvil(true)}
      />
    </div>
  );
};
