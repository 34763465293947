import {
  FunctionComponent,
  useState,
  useRef,
  ReactNode,
  useEffect,
  useContext,
} from "react";
import cx from "classnames";
import {
  Thread,
  MessagesStatus,
  MessagesAuthor,
  Message,
  MessagesReason,
} from "../../data/dataMessages";
import { Button, Snackbar, SNACKBAR_TYPES, Typography } from "@lysaab/ui-2";
import { Icon } from "@lysaab/ui-2";
import { dataMessages } from "../../data/dataMessages";
import { AttachFile, FileState } from "./AttachFile";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { UserState } from "../../context/UserContext";
import "./ThreadActions.scss";
import { TranslatedText } from "../TranslatedText";
import { MessagesContext } from "../../context/MessagesContext";
import AnimateHeight from "react-animate-height";
import { DateTime } from "luxon";

const messages = defineMessages({
  adminAnswer: {
    id: "messages.actions.getback",
  },
});
interface Props {
  thread: Thread;
  user: UserState;
  reloadMessages?: () => void;
  setIsLoading?: () => void;
}

const errorEmptyMessage = (
  <span>
    <TranslatedText id="messages.actions.empty.error" />
  </span>
);

const errorSaveMessage = (
  <span>
    <TranslatedText id="messages.actions.save.error" />
  </span>
);

export const ThreadActions: FunctionComponent<Props> = ({
  thread,
  user,
  reloadMessages,
  setIsLoading,
}) => {
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState<string>("");
  const [error, setError] = useState<ReactNode | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [fileStatuses, setFileStatuses] = useState<Record<string, FileState>>(
    {}
  );
  const messagesContext = useContext(MessagesContext);
  const intl = useIntl();
  const [internalClose, setInternalClose] = useState(false);

  const showOverlay = () => {
    if (document.activeElement === textareaRef.current) {
      return;
    }
    setEdit(!!text);
  };

  const save = () => {
    if (!text) {
      setError(errorEmptyMessage);
      return;
    }

    const attachmentNameAndIds: {
      id: number;
      filename: string;
    }[] = [];

    Object.entries(fileStatuses).forEach(([filename, file]) => {
      if (file.id !== undefined) {
        attachmentNameAndIds.push({ id: file.id, filename });
      }
    });

    const newMessage: Message = {
      message: text,
      attachments: attachmentNameAndIds,
      date: new Date().toISOString(),
      author: MessagesAuthor.CUSTOMER,
    };

    const temporaryAdminAnswer: Message = {
      message: intl.formatMessage<React.ReactNode>(messages.adminAnswer, {
        bold: (str) => <strong>{str}</strong>,
      }),
      attachments: [],
      date: new Date().toISOString(),
      author: MessagesAuthor.ADMIN,
      isGenerated: true,
    };

    const oldMessages = [...thread.messages];
    const oldStatus = thread.status;
    const threads = [...messagesContext.state.messages];
    const activeThread = threads.find((t) => t.id === thread.id);
    if (activeThread) {
      if (activeThread.messages[activeThread.messages.length - 1].isGenerated) {
        activeThread.messages.splice(activeThread.messages.length - 1, 1);
      }
      activeThread.messages.push(newMessage);
      activeThread.messages.push(temporaryAdminAnswer);
      activeThread.status = MessagesStatus.WAITING_FOR_ADMIN;
    }
    messagesContext.setState({ messages: threads });

    dataMessages
      .saveMessage({
        id: thread.id,
        attachments: attachmentNameAndIds.map((file) => file.id),
        message: text,
      })
      .then(() => setText(""))
      .catch((err) => {
        const threads = [...messagesContext.state.messages];
        const activeThread = threads.find((t) => t.id === thread.id);
        if (activeThread) {
          activeThread.messages = oldMessages;
          activeThread.status = oldStatus;
        }
        messagesContext.setState({ messages: threads });
        setError(errorSaveMessage);
      });
  };

  useEffect(() => {
    if (!error) {
      return;
    }
    const timer = setTimeout(() => setError(null), 3000);
    return () => clearTimeout(timer);
  }, [error]);

  if (thread.status === MessagesStatus.CLOSED) {
    return null;
  }

  if (internalClose) {
    return (
      <div className="thread-actions">
        <Typography
          component="p"
          className="internal-close-info"
          variant="secondary"
          type="body"
        >
          <FormattedMessage id="messages.actions.overlay.is-closed" />
        </Typography>
      </div>
    );
  }

  return (
    <div
      className={cx("thread-actions", {
        "is-edit": edit,
        "has-error": !!error,
      })}
    >
      <div className="message-error">
        <Snackbar type={SNACKBAR_TYPES.ERROR}>{error}</Snackbar>
      </div>

      <div className="thread-actions-body">
        <div className={cx("textarea-wrapper", { "is-edit": edit })}>
          {typeof setIsLoading !== "undefined" &&
            typeof reloadMessages !== "undefined" && (
              <Button
                variant="secondary"
                block
                onClick={() => {
                  setInternalClose(true);
                  dataMessages
                    .deleteMessage({
                      id: thread.id,
                      reason: MessagesReason.CLOSED_BY_CUSTOMER,
                    })
                    .then((newThread) => {
                      const threads = [...messagesContext.state.messages];
                      const newMessages = newThread.messages;
                      newMessages.sort(
                        (a, b) =>
                          DateTime.fromISO(a.date).diff(
                            DateTime.fromISO(b.date)
                          ).milliseconds
                      );
                      const activeThread = threads.find(
                        (t) => t.id === thread.id
                      );
                      if (activeThread) {
                        activeThread.messages = newThread.messages;
                      }
                      messagesContext.setState({ messages: threads });
                    });
                }}
                label={
                  <FormattedMessage id="messages.actions.overlay.close-button" />
                }
                className="close-button"
              />
            )}

          <div className="textarea-placeholder">
            <div className="reply-icon">
              <Icon.Close />{" "}
              <span>
                <FormattedMessage id="messages.actions.overlay.answer" />
              </span>
            </div>
            <textarea
              onChange={(event) => setText(event.target.value)}
              value={text}
              onMouseEnter={() => setEdit(true)}
              onMouseOut={showOverlay}
              onFocus={() => setEdit(true)}
              onBlur={showOverlay}
              ref={textareaRef}
            />
          </div>
          <AnimateHeight animateOpacity height={text.length > 0 ? "auto" : 0}>
            <div className="flex thread-actions-buttons">
              <Button
                variant="primary"
                size="small"
                block
                onClick={save}
                className="send"
                label={<FormattedMessage id="messages.actions.answer" />}
              />

              <AttachFile
                id="threadFileInput"
                fileStatuses={fileStatuses}
                onChange={(data) => setFileStatuses(data)}
              />
            </div>
          </AnimateHeight>
        </div>
      </div>
    </div>
  );
};
