import { useState, useCallback, useEffect } from "react";
import * as React from "react";
import { InvestmentAccount } from "../../../../data/dataAccounts";
import { useInView } from "react-intersection-observer";
import "./PositionsData.scss";
import { Status, Retry } from "../../../../components/retry/Retry";
import { FundType, Fund, dataFunds } from "../../../../data/dataFunds";
import { Amount } from "../../../../components/amount/Amount";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { Disclaimer } from "../../Disclaimer";
import { PositionsList } from "../components/PositionsList";
import { CardList, Typography } from "@lysaab/ui-2";
import { CardBottom } from "../../CardBottom";
import { Modal } from "../../../../components/modal/Modal";

const messages = defineMessages({
  header: {
    id: "accountPage.positions.data.holdings",
  },
});

interface Props {
  account: InvestmentAccount | undefined;
}

function checkPositions(account?: InvestmentAccount) {
  if (!account) {
    return {
      hasStocks: false,
      hasBonds: false,
    };
  }
  let hasStocks = false;
  let hasBonds = false;
  Object.values(account.positions).forEach((item) => {
    hasStocks = hasStocks || item.type === FundType.STOCKS;
    hasBonds = hasBonds || item.type === FundType.BONDS;
  });
  return {
    hasStocks,
    hasBonds,
  };
}

export const PositionsData: React.FC<Props> = ({ account }: Props) => {
  const intl = useIntl();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [holdings, setHoldings] = useState<Fund[]>();
  const { hasBonds, hasStocks } = checkPositions(account);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const [showModal, setShowModal] = useState(false);

  const load = useCallback(() => {
    dataFunds
      .getHoldings()
      .then((data) => {
        setHoldings(data);
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, []);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);

    setTimeout(load, 600);
  }, [load]);

  useEffect(() => {
    if (!inView) {
      return;
    }
    load();
  }, [inView, load]);

  if (account && account.worth === 0) {
    return null;
  }

  if (
    account &&
    Object.entries(account.positions).length === 0 &&
    account.positions.constructor === Object
  ) {
    return null;
  }

  return (
    <section className="account-page-positions-data" ref={ref}>
      <Typography type="h2">{intl.formatMessage(messages.header)}</Typography>

      <Retry retry={retry} status={status}>
        {!!account?.uninvestedMoney && (
          <div className="cash-assets">
            <FormattedMessage id="accountPage.positions.data.cashAssets" />
            : <Amount amount={account.uninvestedMoney} />
          </div>
        )}
        <CardList className="list">
          <div className="account-page-padder">
            <div className="account-page-card-body">
              {holdings && account && hasStocks && (
                <>
                  <h4>
                    <FormattedMessage id="accountPage.positions.data.stocks" />
                  </h4>
                  <PositionsList
                    funds={holdings}
                    account={account}
                    fundType={FundType.STOCKS}
                  />
                </>
              )}
              {holdings && account && hasBonds && (
                <>
                  <h4>
                    <FormattedMessage id="accountPage.positions.data.bonds" />
                  </h4>
                  <PositionsList
                    funds={holdings}
                    account={account}
                    fundType={FundType.BONDS}
                  />
                </>
              )}
              <div className="fade" />
            </div>
            <CardBottom>
              <button className="as-link" onClick={() => setShowModal(true)}>
                <FormattedMessage id="accountPage.positions.data.cta.show-holdings" />
              </button>
            </CardBottom>
          </div>
        </CardList>
      </Retry>

      <Disclaimer>
        <FormattedMessage id="accountPage.positions.data.disclaimer" />
      </Disclaimer>

      <Modal
        header={intl.formatMessage(messages.header)}
        showModal={showModal}
        onClose={() => setShowModal(false)}
        closeOnOverlayClick
      >
        <div className="modal-list">
          {holdings && account && hasStocks && (
            <>
              <h4>
                <FormattedMessage id="accountPage.positions.data.stocks" />
              </h4>
              <PositionsList
                funds={holdings}
                account={account}
                fundType={FundType.STOCKS}
              />
            </>
          )}
          {holdings && account && hasBonds && (
            <>
              <h4>
                <FormattedMessage id="accountPage.positions.data.bonds" />
              </h4>
              <PositionsList
                funds={holdings}
                account={account}
                fundType={FundType.BONDS}
              />
            </>
          )}
        </div>
      </Modal>
    </section>
  );
};
