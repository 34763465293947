import { NewIcon as Icon } from "@lysaab/ui-2";
import { AvailableBank } from "../../data/dataTink";
import { BankUrlIcon } from "./BankUrlIcon";
import "./BankListItem.scss";

interface Props {
  bank: AvailableBank;
  onComplete: (bank: AvailableBank) => void;
}

export const BankListItem: React.VFC<Props> = ({ bank, onComplete }) => {
  return (
    <button
      type="button"
      className="transparent-button card-list-item"
      onClick={() => onComplete(bank)}
    >
      <BankUrlIcon url={bank.iconUrl} />
      <div className="card-list-item__label">
        {bank.name}
        <Icon.ChevronRight />
      </div>
    </button>
  );
};
