import {
  API,
  BankIdInitResponse,
  BankIdStandardResponses,
  cache,
  encode,
  OrderRef,
  Status,
} from "@lysaab/ui-2";
import { InvestmentAccountId } from "./dataAccounts";
import { banks } from "./dataBanks";

export enum KF_WITHDRAWAL_STATUS {
  PENDING_DANICA = "PENDING_DANICA",
  PENDING_SIGNING = "PENDING_SIGNING",
  PENDING_DANICA_MANUAL_VERIFICATION = "PENDING_DANICA_MANUAL_VERIFICATION",
  WITHDRAWAL_ONGOING = "WITHDRAWAL_ONGOING",
  WITHDRAWAL_COMPLETED = "WITHDRAWAL_COMPLETED",
  REJECTED = "REJECTED",
  ERROR = "ERROR",
  EXPIRED = "EXPIRED",
}

export enum KF_WITHDRAWAL_PENDING_STATUS {
  PENDING_DANICA = "PENDING_DANICA",
  PENDING_DANICA_MANUAL_VERIFICATION = "PENDING_DANICA_MANUAL_VERIFICATION",
  PENDING_SIGNING = "PENDING_SIGNING",
  WITHDRAWAL_ONGOING = "WITHDRAWAL_ONGOING",
}

export enum KF_SIGN_STATUS {
  COMPLETED = "COMPLETED",
  WAITING = "WAITING",
}

export interface ExternalKFAccount {
  externalBankAccount: string;
  bank: keyof typeof banks;
}

export interface WithdrawalKFAccount {
  accountId: InvestmentAccountId;
  pendingWithdrawal?: string;
  blockedByRecentWithdrawal: boolean;
  pendingMove?: string;
}

interface PutKfWithdrawalRequest {
  from: InvestmentAccountId;
  to: string;
  amount?: number;
  complete: boolean;
}

interface PutKfWithdrawalResponse {
  accountId: InvestmentAccountId;
  accountName: string;
  initiatorName: string;
  requested: string;
  externalBankAccount: string;
  bank: keyof typeof banks;
  drain: boolean;
  amount: number;
  accountWorth: number;
  signingId: string;
}

interface PutKfMigrationRequest {
  from: InvestmentAccountId;
  type: MoveType;
}

export interface PendingKfWithdrawalResponse extends PutKfWithdrawalResponse {
  status: KF_WITHDRAWAL_PENDING_STATUS;
}

interface SignRequest {
  fullName: string;
  userId: number;
  signStatus: KF_SIGN_STATUS;
}

export interface GetKfWithdrawalStatus {
  status: KF_WITHDRAWAL_STATUS;
  lastSigningDateTime: string;
  signRequests: SignRequest[];
  withdrawal: PutKfWithdrawalResponse;
}

export interface WithdrawalSignResponse {
  transactionId: string;
  autostartToken: string;
  status: Status;
}

enum AccountWorthStatus {
  FETCHING = "FETCHING",
  COMPLETED = "COMPLETED",
}

interface AccountWorthFetching {
  status: AccountWorthStatus.FETCHING;
}

export interface AccountWorthComplete {
  status: AccountWorthStatus.COMPLETED;
  maxTransfer: number;
  minTransfer: number;
}

export interface DanicaWithdrawalStatus {
  closed: boolean;
}

type AccountWorthResponse = AccountWorthFetching | AccountWorthComplete;

export enum MoveType {
  CASH = "CASH",
  SHARES = "SHARES",
}

export interface KfActiveMigration {
  accounts: number;
  sum: number;
}

export interface KfMoveAmount {
  amount: number;
}

export const dataDanica = {
  getExternalKFAccounts: () => {
    return API.get<ExternalKFAccount[]>("/danica/withdrawal/external-accounts");
  },

  getWithdrawalKFAccounts: () => {
    return API.get<WithdrawalKFAccount[]>("/danica/withdrawal/accounts");
  },

  putKfWithdrawal: ({ from, to, amount, complete }: PutKfWithdrawalRequest) => {
    if (complete) {
      return API.put<PutKfWithdrawalResponse>(
        `/danica/withdrawal/${from}/complete`,
        {
          externalBankAccount: to,
        }
      ).then((resp) => {
        cache.delete(`/danica/withdrawal`);
        return resp;
      });
    } else {
      return API.put<PutKfWithdrawalResponse>(`/danica/withdrawal/${from}`, {
        amount,
        externalBankAccount: to,
      }).then((resp) => {
        cache.delete(`/danica/withdrawal`);
        return resp;
      });
    }
  },

  putKfMigration: ({ from, type }: PutKfMigrationRequest) => {
    return API.put<PutKfWithdrawalResponse>(`/danica/withdrawal/${from}/move`, {
      type,
    }).then((resp) => {
      cache.delete(`/danica/withdrawal`);
      return resp;
    });
  },

  getKfWithdrawalStatus: (accountId: InvestmentAccountId) => {
    return API.get<GetKfWithdrawalStatus>(
      `/danica/withdrawal/signing/${accountId}`,
      true
    );
  },

  kfWithdrawalSign: (signingId: string, userId: string) => {
    return API.post<BankIdInitResponse>(
      `/danica/withdrawal/signing/${signingId}/start/${userId}`
    );
  },

  kfWithdrawalPoll: (signingId: string, orderRef: OrderRef) => {
    return API.get<BankIdStandardResponses & { qrCode?: string }>(
      `/danica/withdrawal/signing/${signingId}/poll/${orderRef}`,
      true
    ).then((resp) => {
      cache.delete(`/danica/withdrawal`);
      return resp;
    });
  },

  getKfMigrationStatus: (signingId: string) => {
    return API.get<GetKfWithdrawalStatus>(
      `/danica/withdrawal/signing/${signingId}/move`,
      true
    );
  },

  kfMigrationSign: (signingId: string, userId: string) => {
    return API.post<WithdrawalSignResponse>(
      `/danica/withdrawal/signing/${signingId}/start/${userId}/move`
    );
  },

  kfMigrationPoll: (signingId: string, transactionId: string) => {
    return API.get<{ status: Status }>(
      `/danica/withdrawal/signing/${signingId}/poll/${transactionId}/move`,
      true
    ).then((resp) => {
      cache.delete(`/danica/withdrawal`);
      return resp;
    });
  },

  kfShareByEmail: (signingId: string, email: string) => {
    return API.post(`/danica/withdrawal/signing/${signingId}/share-by-email`, {
      email,
    });
  },

  kfShareMigartionByEmail: (signingId: string, email: string) => {
    return API.post(
      `/danica/withdrawal/signing/${signingId}/share-by-email/move`,
      {
        email,
      }
    );
  },

  getMigrationsInTransit: () => {},

  getPendingKFWithdrawals: () => {
    return API.get<PendingKfWithdrawalResponse[]>("/danica/withdrawal/pending");
  },

  /**
   * This is a polling endpoint that can take up towards 30s to finish
   */
  getWithdrawableAmount: (accountId: InvestmentAccountId) => {
    return new Promise<AccountWorthComplete>((resolve, reject) => {
      /**
       * This has to be an object so that the closure is correct
       */
      const pollData = {
        tries: 0,
      };
      const poll = (delay = 3000) => {
        setTimeout(() => {
          pollData.tries++;
          API.get<AccountWorthResponse>(
            encode`/danica/withdrawal/accounts/${accountId}/withdrawable-amount`
          )
            .then((resp) => {
              if (resp.status === AccountWorthStatus.COMPLETED) {
                resolve(resp);
              } else {
                /**
                 * We only want to cache complete requests and the best way to accomplish this is by removing
                 * any non-completed requests from the cache
                 */
                cache.delete(
                  encode`/danica/withdrawal/accounts/${accountId}/withdrawable-amount`
                );
                /**
                 * According to the docs any request that take more than 30s can me seen as failed
                 */
                if (pollData.tries >= 10) {
                  reject();
                }
                poll();
              }
            })
            .catch(reject);
        }, delay);
      };
      poll(0);
    });
  },

  /**
   * Futur does not accept withdrawals during the last days of each year.
   * Withdrawal are closed from the 29th dec and is opened again on the 1st of Jan.
   * This endpoint returns a boolean indicating if withdrawals are closed or not.
   */
  getIsWithdrawalsClosed: () => {
    return API.get<DanicaWithdrawalStatus>("/danica/withdrawal/closed");
  },

  getKfActiveMigrations: () => {
    return API.get<KfActiveMigration>("/danica/withdrawal/move");
  },

  getKfMoveAmount: (accountId: InvestmentAccountId) => {
    return API.get<KfMoveAmount>(`/danica/withdrawal/move/${accountId}`);
  },

  getIsMovedFromFutur: (accountId: InvestmentAccountId) => {
    return API.get<{ completed?: string }>(
      `/danica/accounts/${accountId}/move/completed`
    );
  },
};
