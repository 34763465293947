import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { Button, Success, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { Ingress } from "../components/ingress/Ingress";
import { MoveSteps } from "../components/moveSteps/MoveSteps";
import { useTransfer } from "../TransferContext";
import { AccountType } from "../../../../../data/dataAccounts";
import { PensionMoveStatus } from "../../../../../data/dataLifePensionMove";

import "./Done.scss";

interface Props {
  next: () => void;
}

export const Done: FunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const [transfer] = useTransfer();

  const accountTypesInTransfer = transfer.moves.reduce((prev, move) => {
    if (move.type) {
      prev.push(move.type);
    }
    return prev;
  }, [] as AccountType[]);

  const pensionMoveStatusesInTransfer = transfer.moves.reduce((prev, move) => {
    if (move.state) {
      prev.push(move.state);
    }
    return prev;
  }, [] as PensionMoveStatus[]);

  return (
    <article className="transfer-pension-done">
      <section className="success">
        <Success />
      </section>
      <Typography type="h2" className="header">
        <TranslatedText id="sweden.transfer-pension.done.header" />
      </Typography>
      <Ingress>
        <TranslatedText id="sweden.transfer-pension.done.ingress" />
      </Ingress>
      <Ingress>
        <TranslatedText id="sweden.transfer-pension.done.note" />
      </Ingress>
      <hr className="divider" />

      <MoveSteps
        accountTypes={accountTypesInTransfer}
        pensionMoveStatuses={pensionMoveStatusesInTransfer}
      />

      <Button
        className="cta"
        block
        variant="primary"
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.done.button",
        })}
        onClick={next}
      />
    </article>
  );
};
