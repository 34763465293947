import { useCallback, useContext, useMemo, useReducer } from "react";
import * as React from "react";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../data/dataCustomerTracking";
import { SEE_NO_EVIL, Store, VIEW_CHANGE_IN_PERCENTAGE } from "../Store";

export interface LayoutState {
  showCookieConsent: boolean;
  hideNav: boolean;
  pageStripped: boolean;
  seeNoEvil: boolean;
  showCurrency: boolean;
  showBanner: boolean;
}

export interface LayoutContextProps {
  state: LayoutState;
  setState: (newState: Partial<LayoutState>) => void;
  updateSeeNoEvil: (newSeeNoEvil: boolean) => void;
}

export const LayoutContext = React.createContext<LayoutContextProps>(
  {} as LayoutContextProps
);

export const withLayout =
  <P extends object>(
    Component: React.ComponentType<React.PropsWithChildren<P>>
  ): React.FC<React.PropsWithChildren<Omit<P, keyof LayoutContextProps>>> =>
  (props) =>
    (
      <LayoutContext.Consumer>
        {(contextProps) => <Component {...(props as P)} {...contextProps} />}
      </LayoutContext.Consumer>
    );

function stateReducer(state: LayoutState, newState: Partial<LayoutState>) {
  return { ...state, ...newState };
}

export const LayoutContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {
    pageStripped: false,
    hideNav: false,
    showCookieConsent: false,
    seeNoEvil: Store.getValue(SEE_NO_EVIL),
    showCurrency: Store.getValue(VIEW_CHANGE_IN_PERCENTAGE),
    showBanner: true,
  });

  const contextValue = useMemo(() => {
    function updateSeeNoEvil(newSeeNoEvil: boolean) {
      setState({
        seeNoEvil: newSeeNoEvil,
      });
      Store.setValue(SEE_NO_EVIL, newSeeNoEvil);
      dataCustomerTrackingService.postEvent({
        eventName: newSeeNoEvil ? "seeNoEvilOn" : "seeNoEvilOff",
        domain: FeatureDomain.GENERAL,
        subDomain: SubDomain.OVERVIEW,
      });
    }

    return {
      state,
      setState,
      updateSeeNoEvil,
    };
  }, [state]);

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useShowCurrency = () => {
  const layout = useContext(LayoutContext);

  if (typeof layout === "undefined") {
    throw new Error("Missing Layout Context Provider");
  }

  const toggleCurrency = useCallback(() => {
    Store.setValue(VIEW_CHANGE_IN_PERCENTAGE, !layout.state.showCurrency);
    layout.setState({
      showCurrency: !layout.state.showCurrency,
    });
  }, [layout]);

  return {
    showCurrency: layout.state.showCurrency,
    toggleCurrency,
  };
};
