import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";

import {
  Button,
  CardList,
  CardListItem,
  Form,
  InputErrorList,
  LysaFormRef,
  NewIcon,
  RequiredValidator,
  SNACKBAR_TYPES,
  Snackbar,
  Spinner,
  Tickbox,
  Typography,
  useForm,
  useValidation,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import {
  InsuranceCompaniesStatusResponse,
  dataLifePensionMove,
} from "../../../../../data/dataLifePensionMove";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { PensionLogo } from "../../../../../components/pensionLogo/PensionLogo";
import { useTransfer } from "../TransferContext";

import "./CollectionMethods.scss";
import { InsurelyDoc } from "../components/insurelyDoc/InsurelyDoc";

interface Props {
  next: () => void;
  toManualMethod: () => void;
}

export type InsuranceCompaniesStatus = InsuranceCompaniesStatusResponse;

export const CollectionMethods: FunctionComponent<Props> = ({
  next,
  toManualMethod,
}) => {
  const formRef = useRef<LysaFormRef>();
  const [insuranceCompanies, setInsuranceCompanies] =
    useState<InsuranceCompaniesStatus[]>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [transfer, setTransfer] = useTransfer();
  const [checked, setChecked] = useState<{
    [insuranceCompany: string]: boolean;
  }>({});

  useEffect(() => {
    dataLifePensionMove.getInsuranceCompaniesStatus().then((response) => {
      const data = response.map((company) => {
        return { ...company };
      });
      setInsuranceCompanies(data);
    });
  }, []);

  if (!insuranceCompanies) {
    return <Spinner />;
  }

  return (
    <section className="transfer-pension-collection-methods">
      <Form
        lysaFormRef={formRef}
        onSubmit={(_) => {
          const selectedCompanies = insuranceCompanies
            .filter((company) => checked[company.insuranceCompany])
            .map((company) => company.insuranceCompany);

          if (formRef.current?.isValid && selectedCompanies.length > 0) {
            dataLifePensionMove
              .initiateCollection(selectedCompanies)
              .then((_) => {
                // If user has added manually and backed up, moves might be set,
                // so we reset it here to avoid problems later in the flow.
                // We do want to support mixing manual and Insurely in the future,
                // so this might not be permanent.
                setTransfer({
                  moves: [],
                  collection: selectedCompanies.join(","),
                });
                next();
              });
          }
        }}
      >
        <FormContent
          insuranceCompanies={insuranceCompanies}
          checked={checked}
          setChecked={setChecked}
          toManualMethod={toManualMethod}
        />
      </Form>
    </section>
  );
};

function FormContent({
  insuranceCompanies,
  checked,
  setChecked,
  toManualMethod,
}: {
  insuranceCompanies: InsuranceCompaniesStatus[];
  checked: { [insuranceCompany: string]: boolean };
  setChecked: React.Dispatch<
    React.SetStateAction<{ [insuranceCompany: string]: boolean }>
  >;
  toManualMethod: () => void;
}) {
  const intl = useIntl();
  const values = useMemo(
    () => Object.values(checked).filter(Boolean),
    [checked]
  );
  const [inputName] = useState(
    "insurance_collection_methods_group_" +
      Math.random().toString(36).substr(2, 9)
  );
  const containerRef = useRef<HTMLFieldSetElement>(null);
  const [selectAll, setSelectAll] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validity, errorMessages, validityClasses, resetValidation] =
    useValidation<boolean[]>(
      values,
      [
        new RequiredValidator(
          intl.formatMessage({
            id: "sweden.transfer-pension.collection-methods.noCompaniesSelected.warning",
          })
        ),
      ],
      false
    );

  useForm(
    inputName,
    validity,
    values,
    resetValidation,
    containerRef,
    errorMessages
  );

  const allAreDown = insuranceCompanies.every(
    ({ downTimeReason }) => downTimeReason
  );

  if (allAreDown) {
    return (
      <>
        <Typography type="h1">
          <TranslatedText id="sweden.transfer-pension.collection-methods.header" />
        </Typography>
        <Typography type="body">
          <TranslatedText id="sweden.transfer-pension.collection-methods.ingress" />
        </Typography>
        <Typography type="body">
          <TranslatedText id="sweden.transfer-pension.collection-methods.ingress-second" />
        </Typography>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="sweden.transfer-pension.collection-methods.all-down" />
        </Snackbar>
        <Button
          block
          variant="secondary"
          type="button"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.collection-methods.add-manually.button",
          })}
          onClick={() => {
            toManualMethod();
          }}
        />
      </>
    );
  }

  return (
    <>
      <Typography type="h1">
        <TranslatedText id="sweden.transfer-pension.collection-methods.header" />
      </Typography>
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.collection-methods.ingress" />
      </Typography>
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.collection-methods.ingress-second" />
      </Typography>
      <fieldset ref={containerRef} className="insurance-company-list-fieldset">
        <CardList shadowStyle={false} className="card-list">
          <CardListItem className="insurance-company-list-item">
            <span className="insurance-company">
              <span className="icon-circle">
                <NewIcon.Search />
              </span>
              <Typography type="label-large">
                <TranslatedText id="sweden.transfer-pension.collection-methods.select-all" />
              </Typography>
            </span>
            <Tickbox
              size={18}
              checked={selectAll}
              alternative={{
                text: "",
                value: "selectAll",
              }}
              onChange={(newValue) => {
                let nextChecked;
                if (!selectAll) {
                  nextChecked = insuranceCompanies.reduce((acc, current) => {
                    return { ...acc, [current.insuranceCompany]: true };
                  }, {});
                } else {
                  nextChecked = insuranceCompanies.reduce((acc, current) => {
                    return { ...acc, [current.insuranceCompany]: false };
                  }, {});
                }
                setChecked(nextChecked);
                setSelectAll((previous) => !previous);
              }}
            />
          </CardListItem>
          {insuranceCompanies.map(
            ({
              insuranceCompany,
              insuranceCompanyDisplayName,
              downTimeReason,
            }) => {
              const isChecked = checked[insuranceCompany];
              return (
                <CardListItem
                  key={insuranceCompany}
                  className="insurance-company-list-item"
                >
                  <span className="insurance-company">
                    <PensionLogo
                      pension={{ key: insuranceCompany }}
                      size={48}
                      className="insurance-company-logo"
                    />
                    <label htmlFor={insuranceCompany}>
                      <Typography type="label-large">
                        {insuranceCompanyDisplayName}
                      </Typography>
                      {downTimeReason && (
                        <Typography
                          type="body-small"
                          className="insurance-company-error-text"
                        >
                          <TranslatedText id="sweden.transfer-pension.collection-methods.down-time" />
                        </Typography>
                      )}
                    </label>
                  </span>
                  {!downTimeReason && (
                    <Tickbox
                      size={18}
                      checked={isChecked}
                      alternative={{
                        text: "",
                        value: insuranceCompany,
                      }}
                      onChange={(newValue) => {
                        setChecked((oldChecked) => {
                          return {
                            ...oldChecked,
                            [insuranceCompany]: !oldChecked[insuranceCompany],
                          };
                        });
                      }}
                      validators={[]}
                    />
                  )}
                </CardListItem>
              );
            }
          )}
        </CardList>
      </fieldset>
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.collection-methods.add-manually.accept-terms" />
      </Typography>
      <InsurelyDoc />
      {errorMessages.length > 0 && (
        <div className="error-container">
          <InputErrorList errorMessages={errorMessages} />
        </div>
      )}
      <Button
        className="primary-button"
        block
        variant="primary"
        type="submit"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.collection-methods.submit.button",
        })}
      />
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.collection-methods.add-manually.info" />
      </Typography>
      <Button
        block
        variant="secondary"
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.collection-methods.add-manually.button",
        })}
        onClick={() => {
          toManualMethod();
        }}
      />
    </>
  );
}
