import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NewIcon } from "@lysaab/ui-2";
import { StepIndicator } from "../../../../../../components/stepIndicator/StepIndicator";
import { AccountType } from "../../../../../../data/dataAccounts";
import { PensionMoveStatus } from "../../../../../../data/dataLifePensionMove";

import "./MoveSteps.scss";

interface Props {
  accountTypes: AccountType[];
  pensionMoveStatuses: PensionMoveStatus[];
}

export const MoveSteps: React.VFC<Props> = ({
  accountTypes,
  pensionMoveStatuses,
}) => {
  const intl = useIntl();

  const includeEmployerStep = accountTypes.some(
    (accountType) => accountType !== AccountType.LYSA_PPF
  );

  const allStepsDone = pensionMoveStatuses.map((pensionMoveStatus) =>
    getStepsDone(pensionMoveStatus, includeEmployerStep)
  );
  const stepsDone = Math.min(Math.max(...allStepsDone, 0));

  return (
    <div className="MoveSteps">
      <StepIndicator
        stepsDone={stepsDone}
        steps={[
          {
            text: intl.formatMessage({
              id: "sweden.move-steps.step.initiated.text",
            }),
          },
          {
            text: intl.formatMessage({
              id: "sweden.move-steps.step.signed.text",
            }),
            activeText: intl.formatMessage({
              id: "sweden.move-steps.step.signed.active-text",
            }),
          },
          ...(includeEmployerStep
            ? [
                {
                  text: intl.formatMessage({
                    id: "sweden.move-steps.step.employer.text",
                  }),
                  activeText: intl.formatMessage({
                    id: "sweden.move-steps.step.employer.active-text",
                  }),
                },
              ]
            : []),
          {
            text: intl.formatMessage({
              id: "sweden.move-steps.step.insurence-company.text",
            }),
            activeText: intl.formatMessage({
              id: "sweden.move-steps.step.insurence-company.active-text",
            }),
          },
          {
            text: intl.formatMessage({
              id: "sweden.move-steps.step.lysa.text",
            }),
            activeText: intl.formatMessage({
              id: "sweden.move-steps.step.lysa.active-text",
            }),
          },
        ]}
      />

      <div className="MoveSteps__info">
        <NewIcon.Clock />
        <span>
          <FormattedMessage id="sweden.move-steps.move-duration" />
        </span>
      </div>
    </div>
  );
};

function getStepsDone(
  pensionMoveStatus: PensionMoveStatus,
  includeEmployerStep: boolean
): number {
  switch (pensionMoveStatus) {
    case PensionMoveStatus.REJECTED_CUSTOMER:
    case PensionMoveStatus.REJECTED_EMPLOYER:
    case PensionMoveStatus.REJECTED_INSTITUTE:
    case PensionMoveStatus.REJECTED_LYSA:
      return 0;

    case PensionMoveStatus.CREATED:
    case PensionMoveStatus.WAITING_SEND_INSURED:
    case PensionMoveStatus.SIGNING_INSURED:
      return 1;

    case PensionMoveStatus.WAITING_SEND_EMPLOYER:
    case PensionMoveStatus.WAITING_FOR_EMPLOYER:
      return 2;

    case PensionMoveStatus.WAITING_SEND_INSTITUTE:
    case PensionMoveStatus.WAITING_FOR_INSTITUTE:
    case PensionMoveStatus.ADDITIONAL_INFO_INSTITUTE:
      return includeEmployerStep ? 3 : 2;

    case PensionMoveStatus.APPROVED_INSTITUTE:
      return includeEmployerStep ? 4 : 3;

    case PensionMoveStatus.DONE:
      return includeEmployerStep ? 5 : 4;
  }
}
