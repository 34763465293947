import { Fragment } from "react";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import {
  CashTransaction,
  CashTransactionType,
} from "../../../../../../data/dataTransactions";

import "../../TransactionCard.scss";

interface MessageWithId {
  id: string;
}

const messages = defineMessages<
  string,
  MessageWithId,
  Record<string, MessageWithId>
>({
  AUTOGIRO: {
    id: "historicTransactionsPage.historicTransactions.transactionCard.depositAutogiro",
  },
  DIRECT_DEPOSIT: {
    id: "historicTransactionsPage.historicTransactions.transactionCard.depositDirectDeposit",
  },
  BANKGIRO: {
    id: "historicTransactionsPage.historicTransactions.transactionCard.depositBankgiro",
  },
  SWISH: {
    id: "historicTransactionsPage.historicTransactions.transactionCard.depositSwish",
  },
  VIRTUAL_ACCOUNT: {
    id: "historicTransactionsPage.historicTransactions.transactionCard.depositVirtualAccount",
  },
  CORRECTION: {
    id: "historicTransactionsPage.historicTransactions.transactionCard.correction",
  },
});

export const DepositTransactionRows = ({
  transaction,
}: {
  transaction: CashTransaction;
}) => {
  const intl = useIntl();
  if (transaction.type !== CashTransactionType.DEPOSIT) return null;
  return (
    <Fragment>
      <div className="transaction-card-row ">
        <dt>
          <TranslatedText id="historicTransactionsPage.historicTransactions.transactionCard.depositType" />
        </dt>
        <dd>
          {messages[transaction.depositChannel]
            ? intl.formatMessage(messages[transaction.depositChannel])
            : transaction.depositChannel}
        </dd>
      </div>
    </Fragment>
  );
};
