import { useState, useContext, useEffect, VoidFunctionComponent } from "react";
import { Spinner, InvestmentType, useDebounceValue } from "@lysaab/ui-2";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { LocalizationContext } from "../../context/LocalizationContext";
import { dataFunds, Fund, FundType, Isin } from "../../data/dataFunds";
import { Positions, PositionsList } from "./PositionsList";
import { dataInvestments } from "../../data/dataInvestments";
import "./EditAllocationPositions.scss";

interface Props {
  risk: number;
  allocationInvestment: number;
  investmentType: InvestmentType;
}

const DEBOUNCE_TIMER = 500;

const messages = defineMessages({
  positionsListHeaderStocks: {
    id: "editallocationpositions.header.stocks",
  },
  positionsListHeaderBonds: {
    id: "editallocationpositions.header.bonds",
  },
});

export const EditAllocationPositions: VoidFunctionComponent<Props> = ({
  risk,
  allocationInvestment,
  investmentType,
}) => {
  const [loading, setLoading] = useState(true);
  const [funds, setFunds] = useState<Fund[]>();
  const [positions, setPositions] = useState<Positions>({});
  const localizationContext = useContext(LocalizationContext);
  const type = investmentType || InvestmentType.BROAD;
  const intl = useIntl();

  useEffect(() => {
    dataFunds.getHoldings().then(setFunds);
  }, []);

  const debouncedRisk = useDebounceValue(risk, DEBOUNCE_TIMER);

  useEffect(() => {
    if (!localizationContext.state.country) {
      return;
    }

    dataInvestments
      .getAllocation(debouncedRisk, type, localizationContext.state.country)
      .then((allocation) => {
        const pos: Positions = {};
        Object.keys(allocation).forEach((isin) => {
          pos[isin as Isin] = {
            worth: (allocationInvestment * allocation[isin as Isin]) / 100,
          };
        });
        setPositions(pos);
        setLoading(false);
      });
  }, [
    debouncedRisk,
    type,
    allocationInvestment,
    localizationContext.state.country,
  ]);

  return (
    <div className="edit-allocation-positions-page">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <PositionsList
            header={intl.formatMessage(messages.positionsListHeaderStocks)}
            holdings={funds}
            positions={positions}
            type={FundType.STOCKS}
          />
          <PositionsList
            header={intl.formatMessage(messages.positionsListHeaderBonds)}
            holdings={funds}
            positions={positions}
            type={FundType.BONDS}
          />
        </>
      )}

      <div className="disclaimer">
        <FormattedMessage id="editallocationpositions.text.disclaimer" />
      </div>
    </div>
  );
};
