import { API, cache, encode, InvestmentType } from "@lysaab/ui-2";
import { isLanguage, Language } from "../context/LocalizationContext";
import { Isin } from "./dataFunds";
import { InvestmentAccountId } from "./dataAccounts";
import { LysaCountry } from "@lysaab/shared";
import { LegalEntityType } from "./dataLogin";

export type TransactionId = string & { readonly isTransactionId: true };

export enum SavingsHorizonLength {
  VERY_LONG = "VERY_LONG",
  LONG = "LONG",
  KINDA_LONG = "KINDA_LONG",
  MIDDLE = "MIDDLE",
  SHORT = "SHORT",
}

export enum NeedEarlierProbability {
  VERY_UNLIKELY = "VERY_UNLIKELY",
  UNLIKELY = "UNLIKELY",
  SOMEWHAT_UNLIKELY = "SOMEWHAT_UNLIKELY",
  SOMEWHAT_LIKELY = "SOMEWHAT_LIKELY",
  LIKELY = "LIKELY",
  VERY_LIKELY = "VERY_LIKELY",
}

export enum SustainabilityImportance {
  NOT_IMPORTANT = "NOT_IMPORTANT",
  IMPORTANT = "IMPORTANT",
}

export enum SustainabilityPreference {
  NONE = "NONE",
  SPECIFIC = "SPECIFIC",
}

export enum PAIImportance {
  NOT_IMPORTANT = "NOT_IMPORTANT",
  IMPORTANT = "IMPORTANT",
}

export enum SFDRImportance {
  NONE = "NONE",
  LEAST_25 = "LEAST_25",
  LEAST_50 = "LEAST_50",
  LEAST_75 = "LEAST_75",
}

export enum TaxonomyImportance {
  NONE = "NONE",
  LEAST_25 = "LEAST_25",
  LEAST_50 = "LEAST_50",
  LEAST_75 = "LEAST_75",
}

export const isSavingsHorizonLength = (
  value?: unknown
): value is SavingsHorizonLength => {
  return (
    typeof value === "string" &&
    Object.values(SavingsHorizonLength).some((v) => value === v)
  );
};

export const isNeedEarlierProbability = (
  value?: unknown
): value is NeedEarlierProbability => {
  return (
    typeof value === "string" &&
    Object.values(NeedEarlierProbability).some((v) => value === v)
  );
};

export const isSustainabilityImportance = (
  value?: unknown
): value is SustainabilityImportance => {
  return (
    typeof value === "string" &&
    Object.values(SustainabilityImportance).some((v) => value === v)
  );
};

export const isSustainabilityPreference = (
  value?: unknown
): value is SustainabilityPreference => {
  return (
    typeof value === "string" &&
    Object.values(SustainabilityPreference).some((v) => value === v)
  );
};

export const isPAIImportance = (value: unknown): value is PAIImportance => {
  return (
    typeof value === "string" &&
    Object.values(PAIImportance).some((v) => value === v)
  );
};

export const isSFDRImportance = (value: unknown): value is SFDRImportance => {
  return (
    typeof value === "string" &&
    Object.values(SFDRImportance).some((v) => value === v)
  );
};

export const isTaxonomyImportance = (
  value?: unknown
): value is TaxonomyImportance => {
  return (
    typeof value === "string" &&
    Object.values(TaxonomyImportance).some((v) => value === v)
  );
};

/** Pension **/

export interface PensionQuestions {
  withdrawalAge: number;
  withdrawalMonths: number;
}

/** Horizon **/

export interface HorizonQuestions {
  needEarlier: NeedEarlierProbability;
  savingsHorizon: SavingsHorizonLength;
}

export function isHorizonUpdated(
  data: Partial<HorizonQuestions>,
  oldData: Partial<HorizonQuestions>
): boolean {
  const [horizon, oldHorizon] = [data, oldData].map((horizon) => {
    return {
      needEarlier: horizon.needEarlier,
      savingsHorizon: horizon.savingsHorizon,
    };
  });

  return JSON.stringify(horizon) !== JSON.stringify(oldHorizon);
}

export function isValidHorizon(data: unknown): data is HorizonQuestions {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!("needEarlier" in data) || !isNeedEarlierProbability(data.needEarlier)) {
    return false;
  }

  if (
    !("savingsHorizon" in data) ||
    !isSavingsHorizonLength(data.savingsHorizon)
  ) {
    return false;
  }

  return true;
}

export function getHorizonQuestions(data: unknown): HorizonQuestions {
  if (!isValidHorizon(data)) {
    throw new Error(
      "getHorizonQuestions - data didn't fulfill requirements for being HorizonQuestions"
    );
  }

  const horizonQuestions = {
    needEarlier: data.needEarlier,
    savingsHorizon: data.savingsHorizon,
  };

  return horizonQuestions;
}

export function isValidPension(data: unknown): data is PensionQuestions {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!("withdrawalAge" in data)) {
    return false;
  }

  if (!("withdrawalMonths" in data)) {
    return false;
  }

  return true;
}

/** Sustainability **/

export interface SustainabilityQuestionsNotImportant {
  sustainability: SustainabilityImportance.NOT_IMPORTANT;
}

export interface SustainabilityQuestionsImportantNone {
  sustainability: SustainabilityImportance.IMPORTANT;
  sustainabilityPreference: SustainabilityPreference.NONE;
}

export interface SustainabilityQuestionsImportantSpecific extends EsgQuestions {
  sustainability: SustainabilityImportance.IMPORTANT;
  sustainabilityPreference: SustainabilityPreference.SPECIFIC;
}

export interface EsgQuestions {
  pai: PAIImportance;
  sfdr: SFDRImportance;
  taxonomy: TaxonomyImportance;
}

export function isEsgQuestionsUpdate(
  currentQuestions: Partial<SustainabilityQuestions>,
  previousQuestions: Partial<SustainabilityQuestions>
): boolean {
  const [current, previous] = [currentQuestions, previousQuestions].map(
    (esgQuestions) => {
      if (isSustainabilityImportantSpecific(esgQuestions)) {
        return JSON.stringify({
          pai: esgQuestions.pai,
          sfdr: esgQuestions.sfdr,
          taxonomy: esgQuestions.taxonomy,
        });
      }
      return JSON.stringify({});
    }
  );

  return current !== previous;
}

export type SustainabilityQuestions =
  | SustainabilityQuestionsNotImportant
  | SustainabilityQuestionsImportantNone
  | SustainabilityQuestionsImportantSpecific;

export function isSustainabilityNotImportant(
  data: unknown
): data is SustainabilityQuestionsNotImportant {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !("sustainability" in data) ||
    data.sustainability !== SustainabilityImportance.NOT_IMPORTANT
  ) {
    return false;
  }

  return true;
}

export function isSustainabilityImportantNone(
  data: unknown
): data is SustainabilityQuestionsImportantNone {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !("sustainability" in data) ||
    data.sustainability !== SustainabilityImportance.IMPORTANT
  ) {
    return false;
  }

  if (
    !("sustainabilityPreference" in data) ||
    data.sustainabilityPreference !== SustainabilityPreference.NONE
  ) {
    return false;
  }

  return true;
}

export function isSustainabilityImportantSpecific(
  data: unknown
): data is SustainabilityQuestionsImportantSpecific {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !("sustainability" in data) ||
    data.sustainability !== SustainabilityImportance.IMPORTANT
  ) {
    return false;
  }

  if (
    !("sustainabilityPreference" in data) ||
    data.sustainabilityPreference !== SustainabilityPreference.SPECIFIC
  ) {
    return false;
  }

  return true;
}

export function isSustainabilityUpdated(
  data: Partial<SustainabilityQuestions>,
  oldData: Partial<SustainabilityQuestions>
): boolean {
  const [sustainability, oldSustainability] = [data, oldData].map(
    (sustainabilityQuestions) => {
      if (isSustainabilityImportantNone(sustainabilityQuestions)) {
        return {
          sustainability: sustainabilityQuestions.sustainability,
          sustainabilityPreference:
            sustainabilityQuestions.sustainabilityPreference,
        };
      }

      if (isSustainabilityImportantSpecific(sustainabilityQuestions)) {
        return {
          sustainability: sustainabilityQuestions.sustainability,
          sustainabilityPreference:
            sustainabilityQuestions.sustainabilityPreference,
          pai: sustainabilityQuestions.pai,
          sfdr: sustainabilityQuestions.sfdr,
          taxonomy: sustainabilityQuestions.taxonomy,
        };
      }

      if (isSustainabilityNotImportant(sustainabilityQuestions)) {
        return {
          sustainability: sustainabilityQuestions.sustainability,
        };
      }

      return {};
    }
  );

  return JSON.stringify(sustainability) !== JSON.stringify(oldSustainability);
}

export function isValidSustainability(
  data: unknown
): data is SustainabilityQuestions {
  if (isSustainabilityImportantNone(data)) {
    return (
      isSustainabilityImportance(data.sustainability) &&
      isSustainabilityPreference(data.sustainabilityPreference)
    );
  } else if (isSustainabilityImportantSpecific(data)) {
    return (
      isSustainabilityImportance(data.sustainability) &&
      isSustainabilityPreference(data.sustainabilityPreference) &&
      isPAIImportance(data.pai) &&
      isSFDRImportance(data.sfdr) &&
      isTaxonomyImportance(data.taxonomy)
    );
  } else if (isSustainabilityNotImportant(data)) {
    return isSustainabilityImportance(data.sustainability);
  }
  return false;
}

/** InvestmentAccountQuestions **/

export type InvestmentAccountQuestions = SustainabilityQuestions &
  HorizonQuestions;

export type PensionAccountQuestions = SustainabilityQuestions &
  PensionQuestions;

export type AccountQuestions =
  | InvestmentAccountQuestions
  | PensionAccountQuestions;

export function isValidInvestmentAccountQuestions(
  accountQuestions: AccountQuestions
): accountQuestions is InvestmentAccountQuestions {
  return (
    (accountQuestions as InvestmentAccountQuestions).needEarlier !== undefined
  );
}

export function isValidPensionAccountQuestions(
  accountQuestions: AccountQuestions
): accountQuestions is PensionAccountQuestions {
  return (
    (accountQuestions as PensionAccountQuestions).withdrawalAge !== undefined
  );
}

export function isValidAccountQuestions(
  data: unknown
): data is InvestmentAccountQuestions {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!isValidHorizon(data)) {
    return false;
  }

  if (!isValidSustainability(data)) {
    return false;
  }

  return true;
}

export function isValidAccountQuestionsUpdated(
  data: Partial<InvestmentAccountQuestions>,
  oldData: Partial<InvestmentAccountQuestions>
): boolean {
  return (
    isHorizonUpdated(data, oldData) || isSustainabilityUpdated(data, oldData)
  );
}

export function getAccountQuestions(data: unknown): InvestmentAccountQuestions {
  if (!isValidHorizon(data)) {
    throw new Error(
      "getAccountQuestions - data didn't fulfill requirements for being HorizonQuestions"
    );
  }

  const horizonQuestions = {
    needEarlier: data.needEarlier,
    savingsHorizon: data.savingsHorizon,
  };

  if (!isValidSustainability(data)) {
    throw new Error(
      "getAccountQuestions - data didn't fulfill requirements for being InvestmentAccountQuestions"
    );
  }

  if (isSustainabilityImportantNone(data)) {
    return {
      ...horizonQuestions,
      sustainability: data.sustainability,
      sustainabilityPreference: data.sustainabilityPreference,
    };
  } else if (isSustainabilityImportantSpecific(data)) {
    return {
      ...horizonQuestions,
      sustainability: data.sustainability,
      sustainabilityPreference: data.sustainabilityPreference,
      pai: data.pai,
      sfdr: data.sfdr,
      taxonomy: data.taxonomy,
    };
  } else if (isSustainabilityNotImportant(data)) {
    return {
      ...horizonQuestions,
      sustainability: data.sustainability,
    };
  }

  throw new Error("getAccountQuestions - failed to match any sustainability");
}

export function getPensionAccountQuestions(
  data: unknown
): PensionAccountQuestions {
  if (!isValidPension(data)) {
    throw new Error(
      "getPensionAccountQuestions - data didn't fulfill requirements for being PensionQuestions"
    );
  }

  const pensionQuestions = {
    withdrawalAge: data.withdrawalAge,
    withdrawalMonths: data.withdrawalMonths,
  };

  if (!isValidSustainability(data)) {
    throw new Error(
      "getPensionAccountQuestions - data didn't fulfill requirements for being PensionQuestions"
    );
  }

  if (isSustainabilityImportantNone(data)) {
    return {
      ...pensionQuestions,
      sustainability: data.sustainability,
      sustainabilityPreference: data.sustainabilityPreference,
    };
  } else if (isSustainabilityImportantSpecific(data)) {
    return {
      ...pensionQuestions,
      sustainability: data.sustainability,
      sustainabilityPreference: data.sustainabilityPreference,
      pai: data.pai,
      sfdr: data.sfdr,
      taxonomy: data.taxonomy,
    };
  } else if (isSustainabilityNotImportant(data)) {
    return {
      ...pensionQuestions,
      sustainability: data.sustainability,
    };
  }

  throw new Error("getAccountQuestions - failed to match any sustainability");
}

/** Eligibility **/

// Like Partial but nested
export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export interface EligibilityFinancials {
  monthlyEarnings: number;
  monthlyPayments: number;
  liquidAssets: number;
  otherAssets: number;
  debts: number;
}

export function isValidEligibilityFinancials(
  data?: unknown
): data is EligibilityFinancials {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !("monthlyEarnings" in data) ||
    typeof data.monthlyEarnings !== "number"
  ) {
    return false;
  }

  if (
    !("monthlyPayments" in data) ||
    typeof data.monthlyPayments !== "number"
  ) {
    return false;
  }

  if (!("liquidAssets" in data) || typeof data.liquidAssets !== "number") {
    return false;
  }

  if (!("otherAssets" in data) || typeof data.otherAssets !== "number") {
    return false;
  }

  if (!("debts" in data) || typeof data.debts !== "number") {
    return false;
  }

  return true;
}

export enum EligibilityRiskAnswer {
  IMPORTANCE = "1",
  REACTION = "2",
  PROPENSITY = "3",
}

export interface EligibilityRiskAnswers {
  [EligibilityRiskAnswer.IMPORTANCE]: RiskImportance;
  [EligibilityRiskAnswer.REACTION]: RiskReaction;
  [EligibilityRiskAnswer.PROPENSITY]: RiskPropensity;
}

export function isValidEligibilityRiskAnswers(
  data?: unknown
): data is EligibilityRiskAnswers {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !(EligibilityRiskAnswer.IMPORTANCE in data) ||
    !isRiskImportance(data[EligibilityRiskAnswer.IMPORTANCE])
  ) {
    return false;
  }

  if (
    !(EligibilityRiskAnswer.REACTION in data) ||
    !isRiskReaction(data[EligibilityRiskAnswer.REACTION])
  ) {
    return false;
  }

  if (
    !(EligibilityRiskAnswer.PROPENSITY in data) ||
    !isRiskPropensity(data[EligibilityRiskAnswer.PROPENSITY])
  ) {
    return false;
  }

  return true;
}

export interface EligibilityRisk {
  answers: EligibilityRiskAnswers;
}

export function isValidEligibilityRisk(
  data?: unknown
): data is EligibilityRisk {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!("answers" in data) || !isValidEligibilityRiskAnswers(data.answers)) {
    return false;
  }

  return true;
}

export function getEligibilityRisk(data: unknown): EligibilityRisk {
  if (isValidEligibilityRisk(data)) {
    return {
      answers: {
        [EligibilityRiskAnswer.IMPORTANCE]:
          data.answers[EligibilityRiskAnswer.IMPORTANCE],
        [EligibilityRiskAnswer.REACTION]:
          data.answers[EligibilityRiskAnswer.REACTION],
        [EligibilityRiskAnswer.PROPENSITY]:
          data.answers[EligibilityRiskAnswer.PROPENSITY],
      },
    };
  }

  throw new Error(
    "getEligibilityRisk - not a Eligibility or EligibilityRisk object"
  );
}

export interface EligibilityPerson {
  legalEntityType: LegalEntityType.PERSON;
  financial: EligibilityFinancials;
  risk: EligibilityRisk;
}

export function isEligibilityPerson(data: unknown): data is EligibilityPerson {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !("legalEntityType" in data) ||
    data.legalEntityType !== LegalEntityType.PERSON
  ) {
    return false;
  }

  if (
    !("risk" in data) ||
    typeof data.risk !== "object" ||
    data.risk === null
  ) {
    return false;
  }

  if (
    !("financial" in data) ||
    typeof data.financial !== "object" ||
    data.financial === null
  ) {
    return false;
  }

  return true;
}

export function isValidEligibilityPerson(
  data?: unknown
): data is EligibilityPerson {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!isEligibilityPerson(data)) {
    return false;
  }

  if (!isValidEligibilityFinancials(data.financial)) {
    return false;
  }

  if (!isValidEligibilityRisk(data.risk)) {
    return false;
  }

  return true;
}

export interface EligibilityCorporation {
  legalEntityType: LegalEntityType.CORPORATION;
  risk: EligibilityRisk;
}

export function isEligibilityCorporation(
  data: unknown
): data is EligibilityCorporation {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !("legalEntityType" in data) ||
    data.legalEntityType !== LegalEntityType.CORPORATION
  ) {
    return false;
  }

  if (
    !("risk" in data) ||
    typeof data.risk !== "object" ||
    data.risk === null
  ) {
    return false;
  }

  if ("financial" in data) {
    return false;
  }

  return true;
}

export function isValidEligibilityCorporation(
  data?: unknown
): data is EligibilityCorporation {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!isEligibilityCorporation(data)) {
    return false;
  }

  if (!isValidEligibilityRisk(data.risk)) {
    return false;
  }

  return true;
}

export type Eligibility = EligibilityPerson | EligibilityCorporation;

export function isValidEligibility(data?: unknown): data is Eligibility {
  if (typeof data === "undefined") {
    return false;
  }

  if (isEligibilityPerson(data)) {
    if (!isValidEligibilityPerson(data)) {
      return false;
    }
  }

  if (isEligibilityCorporation(data)) {
    if (!isValidEligibilityCorporation(data)) {
      return false;
    }
  }

  return true;
}

export function getEligibility(data: unknown): Eligibility {
  if (isValidEligibilityPerson(data)) {
    return {
      legalEntityType: data.legalEntityType,
      financial: {
        debts: data.financial.debts,
        liquidAssets: data.financial.liquidAssets,
        monthlyEarnings: data.financial.monthlyEarnings,
        monthlyPayments: data.financial.monthlyPayments,
        otherAssets: data.financial.otherAssets,
      },
      risk: getEligibilityRisk(data.risk),
    };
  }

  if (isValidEligibilityCorporation(data)) {
    return {
      legalEntityType: data.legalEntityType,
      risk: getEligibilityRisk(data.risk),
    };
  }

  throw new Error(
    `getEligibility - data didn't fulfill requirements for being any legal entity type`
  );
}

export enum RiskImportance {
  MAXIMIZE = 10,
  MINIMIZE = 0,
  BOTH = 5,
}

export function isRiskImportance(value: unknown): value is RiskImportance {
  if (typeof value !== "number") {
    return false;
  }

  return Object.values(RiskImportance).some(
    (k) => typeof k === "number" && k === value
  );
}

export enum RiskReaction {
  SELL = 3,
  KEEP = 7,
  BUY = 10,
}

export function isRiskReaction(value: unknown): value is RiskReaction {
  if (typeof value !== "number") {
    return false;
  }

  return Object.values(RiskReaction).some(
    (k) => typeof k === "number" && k === value
  );
}

export enum RiskPropensity {
  BAD = 10,
  PRETTY_BAD = 8,
  GOOD = 6,
  PRETTY_GOOD = 4,
  TOO_RISKY = 2,
}

export function isRiskPropensity(value: unknown): value is RiskPropensity {
  if (typeof value !== "number") {
    return false;
  }

  return Object.values(RiskPropensity).some(
    (k) => typeof k === "number" && k === value
  );
}

/** Request & Responses **/

export type AccountInvestmentProfile = {
  accountId: InvestmentAccountId;
} & InvestmentAccountQuestions;

export function isValidAccountInvestmentProfile(
  data?: unknown
): data is AccountInvestmentProfile {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!("accountId" in data) || typeof data.accountId !== "string") {
    return false;
  }

  if (!isValidAccountQuestions(data)) {
    return false;
  }

  return true;
}

export type AccountInvestmentProfileUpdate = {
  accountId: InvestmentAccountId;
  takenRisk: number;
} & AccountInvestmentProfile;

export function isValidAccountInvestmentProfileUpdate(
  data?: unknown
): data is AccountInvestmentProfileUpdate {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!("takenRisk" in data) || typeof data.takenRisk !== "number") {
    return false;
  }

  if (!("accountId" in data) || typeof data.accountId !== "string") {
    return false;
  }

  if (!isValidAccountInvestmentProfile(data)) {
    return false;
  }

  return true;
}

export interface EsgResultResponse {
  esgResult: {
    esgAnswers: EsgQuestions;
    esgBestMatch?: EsgQuestions;
  };
}

export interface AdviceResponse extends EsgResultResponse {
  advisedRisk: number;
  investmentType: InvestmentType;
  declaration: string;
}

type SharedAdviceAccountProps = {
  accountId: InvestmentAccountId;
  takenRisk: number;
  advisedRisk: number;
  allocationCreated: string;
  declaration: string;
  investmentType: InvestmentType;
} & Partial<EsgResultResponse>;

export type InvestmentAdviseAccount = SharedAdviceAccountProps &
  InvestmentAccountQuestions;

export type PensionAdviseAccount = SharedAdviceAccountProps &
  PensionAccountQuestions;

export type CombinedAdviseAccount =
  | InvestmentAdviseAccount
  | PensionAdviseAccount;

export function isInvestmentAdviseAccount(
  advice: CombinedAdviseAccount
): advice is InvestmentAdviseAccount {
  return (advice as InvestmentAdviseAccount).savingsHorizon !== undefined;
}

export type GetSuitabilityAssessmentRequest = InvestmentAccountQuestions & {
  language: Language;
};

export type GetPensionSuitabilityAssessmentRequest = PensionAccountQuestions & {
  language: Language;
  age: number;
};

export function isValidGetSuitabilityAssessmentRequest(
  data: Partial<GetSuitabilityAssessmentRequest>
): data is GetSuitabilityAssessmentRequest {
  if (!("language" in data) || !isLanguage(data.language)) {
    return false;
  }

  if (!isValidAccountQuestions(data)) {
    return false;
  }

  return true;
}

export type GetSuitabilityAssessmentResponse = AdviceResponse & {
  allocation: Record<Isin, number>;
};

export interface GetAllocationResponse {
  [isin: Isin]: number;
}

export type RecalculateAdviceWithEligibilityRequest =
  InvestmentAccountQuestions & {
    eligibility: Eligibility;
    accountId: InvestmentAccountId;
    language: Language;
  };

export function isValidRecalculateAdviceWithEligibilityRequest(
  data: unknown
): data is RecalculateAdviceWithEligibilityRequest {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!("accountId" in data) || typeof data.accountId !== "string") {
    return false;
  }

  if (!("language" in data) || !isLanguage(data.language)) {
    return false;
  }

  if (!isValidAccountQuestions(data)) {
    return false;
  }

  if (!("eligibility" in data) || !isValidEligibility(data.eligibility)) {
    return false;
  }

  return true;
}

export interface RecalculateAdviceWithEligibilityResponse
  extends AdviceResponse {}

export type RecalculateAdviceRequest = InvestmentAccountQuestions & {};

export function isValidRecalculateAdvicesRequest(
  data: unknown
): data is RecalculateAdviceRequest {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!isValidAccountQuestions(data)) {
    return false;
  }

  return true;
}

export type RecalculateAdvicesWithRiskRequest = {
  risk: EligibilityRisk;
  investmentProfiles: AccountInvestmentProfile[];
};

export function isValidRecalculateAdvicesWithRiskRequest(
  data: unknown
): data is RecalculateAdvicesWithRiskRequest {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!("risk" in data) || !isValidEligibilityRisk(data.risk)) {
    return false;
  }

  if (
    !("investmentProfiles" in data) ||
    !Array.isArray(data.investmentProfiles) ||
    data.investmentProfiles.some(
      (investmentProfile) => !isValidAccountInvestmentProfile(investmentProfile)
    )
  ) {
    return false;
  }

  return true;
}

export type RecalculateAdvicesWithRiskResponse = {
  advice: number;
  updatable: AccountUpdatableStatus;
  accountId: InvestmentAccountId;
  takenRisk: number;
  oldAdvice: number;
  /**
   * ISO timestamp */
  oldAdviceCreated: string;
  investmentType: InvestmentType;
} & InvestmentAccountQuestions &
  EsgResultResponse;

export enum AccountUpdatableStatus {
  OK = "OK",
  NO_ACCESS = "NO_ACCESS",
  ERROR = "ERROR",
}

export type RecalculateAdviceResponse = InvestmentAccountQuestions &
  EsgResultResponse & {
    advice: number;
    updatable: AccountUpdatableStatus;
    accountId: InvestmentAccountId;
    takenRisk: number;
    oldAdvice: number;
    /**
     * ISO timestamp */
    oldAdviceCreated: string;
    investmentType: InvestmentType;
  };

export type UpdateInvestmentProfileRequest = InvestmentAccountQuestions & {
  takenRisk: number;
  language: Language;
};

export function isValidUpdateInvestmentProfileRequest(
  data: unknown
): data is UpdateInvestmentProfileRequest {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!("takenRisk" in data) || typeof data.takenRisk !== "number") {
    return false;
  }

  if (!("language" in data) || !isLanguage(data.language)) {
    return false;
  }

  if (!isValidAccountQuestions(data)) {
    return false;
  }

  return true;
}

export interface UpdateInvestmentProfilesPersonRequest {
  eligibility: EligibilityPerson;
  investmentProfiles: AccountInvestmentProfileUpdate[];
}

export function isValidUpdateInvestmentProfilesPersonRequest(
  data: unknown
): data is UpdateInvestmentProfilesPersonRequest {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!("eligibility" in data) || !isValidEligibilityPerson(data.eligibility)) {
    return false;
  }

  if (
    !("investmentProfiles" in data) ||
    !Array.isArray(data.investmentProfiles) ||
    data.investmentProfiles
      .map(isValidAccountInvestmentProfileUpdate)
      .includes(false)
  ) {
    return false;
  }

  return true;
}

export interface UpdateInvestmentProfilesCorporationRequest {
  eligibility: EligibilityCorporation;
  investmentProfiles: AccountInvestmentProfileUpdate[];
}

export type GlidepathDataPoint = {
  takenRisk: number;
  age: number;
};

export interface GlidepathResponse {
  glidePath: GlidepathDataPoint[];
}

export function isValidUpdateInvestmentProfilesCorporationRequest(
  data: unknown
): data is UpdateInvestmentProfilesCorporationRequest {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !("eligibility" in data) ||
    !isValidEligibilityCorporation(data.eligibility)
  ) {
    return false;
  }

  if (
    !("investmentProfiles" in data) ||
    !Array.isArray(data.investmentProfiles) ||
    data.investmentProfiles
      .map(isValidAccountInvestmentProfileUpdate)
      .includes(false)
  ) {
    return false;
  }

  return true;
}

export type UpdateInvestmentProfilesRequest =
  | UpdateInvestmentProfilesPersonRequest
  | UpdateInvestmentProfilesCorporationRequest;

export function isValidUpdateInvestmentProfilesRequest(
  data: unknown
): data is UpdateInvestmentProfilesRequest {
  if (
    !isValidUpdateInvestmentProfilesPersonRequest(data) &&
    !isValidUpdateInvestmentProfilesCorporationRequest(data)
  ) {
    return false;
  }

  return true;
}

export interface UpdateRiskRequest {
  accountId: InvestmentAccountId;
  takenRisk: number;
}

export type EligibilityResponse = {
  legalEntityType: LegalEntityType;
  saved: string;
} & Eligibility;

export const dataInvestments = {
  getAdviseAccount: (accountId: InvestmentAccountId) => {
    return API.get<InvestmentAdviseAccount>(`/investments/advice/${accountId}`);
  },

  getAdviseAccounts: () => {
    return API.get<CombinedAdviseAccount[]>("/investments/combined/advice");
  },

  getNewAccountSuitability: (data: GetSuitabilityAssessmentRequest) => {
    return API.post<GetSuitabilityAssessmentResponse>(
      `/investments/suitability-assessment/new-account`,
      data
    );
  },

  getNewPensionAccountSuitability: (data: any) => {
    return API.post<GetSuitabilityAssessmentResponse>(
      `/investments/suitability-assessment/pension/new-account`,
      data
    );
  },

  updateAccountInvestment: (
    accountId: InvestmentAccountId,
    data: UpdateInvestmentProfileRequest
  ) => {
    return API.post<EligibilityResponse>(`/investments/advice/${accountId}`, {
      ...data,
    }).then((respons) => {
      cache.delete("/accounts");
      cache.delete("/investments/advice");
      cache.delete("/investments/combined/advice");
      return respons;
    });
  },

  recalculateAdvice: (
    accountId: InvestmentAccountId,
    data: RecalculateAdviceRequest
  ) => {
    return API.post<RecalculateAdviceResponse>(
      `/investments/advice/${accountId}/recalculate`,
      data
    );
  },

  recalculateAdviceWithEligibility: (
    data: RecalculateAdviceWithEligibilityRequest
  ) => {
    const { accountId, ...props } = data;
    return API.post<RecalculateAdviceWithEligibilityResponse>(
      `/investments/advice/${accountId}/with-eligibility/calculate`,
      props
    );
  },

  recalculateAdvicesWithRisk: (data: RecalculateAdvicesWithRiskRequest) => {
    return API.post<RecalculateAdvicesWithRiskResponse[]>(
      "/investments/advice/with-risk/recalculate",
      data
    );
  },

  updateInvestmentProfiles: (data: UpdateInvestmentProfilesRequest) => {
    return API.post(`/investments/profiles/update`, data).then((response) => {
      cache.delete("/investments/eligibility");
      cache.delete("/accounts");
      cache.delete("/investments/advice");
      cache.delete("/investments/combined/advice");
      return response;
    });
  },

  updateRisk: (data: UpdateRiskRequest) => {
    const { accountId, ...body } = data;
    return API.post(`/investments/account/${accountId}/taken-risk`, body).then(
      (response) => {
        cache.delete("/accounts");
        cache.delete("/investments/advice");
        cache.delete("/investments/combined/advice");
        return response;
      }
    );
  },

  getAllocation: (risk: number, type: InvestmentType, country: LysaCountry) => {
    return API.get<GetAllocationResponse>(
      encode`/investments/allocations?risk=${risk}&investmentType=${type}&country=${country}`
    ).then((response) => {
      cache.delete("/accounts");
      cache.delete("/investments/advice");
      cache.delete("/investments/combined/advice");
      cache.delete("/investments/advice/recalculate");
      return response;
    });
  },

  getEligibility: () => {
    return API.get<EligibilityResponse>("/investments/eligibility");
  },

  getPensionGlidepath: (
    age: number,
    withdrawalAge: number,
    withdrawalMonths: number,
    takenRiskDeviation: number
  ) => {
    const searchParams = new URLSearchParams();
    searchParams.append("age", age.toString());
    searchParams.append("withdrawalAge", withdrawalAge.toString());
    searchParams.append("withdrawalMonths", withdrawalMonths.toString());
    searchParams.append("takenRiskDeviation", takenRiskDeviation.toString());
    return API.get<GlidepathResponse>(
      `/investments/pension/glide-path/calculate?${searchParams.toString()}`
    );
  },
};
