import { Fragment, useContext } from "react";
import {
  InvestmentAccount,
  AccountType as AccountTypeEnum,
  isPensionAccountType,
} from "../../data/dataAccounts";
import { CardList, LysaLink } from "@lysaab/ui-2";
import { CardBottom } from "./CardBottom";
import { FormattedMessage } from "react-intl";
import { AccountType } from "../../components/accountType/AccountType";
import "./Docs.scss";
import { InvestmentType } from "../../components/investmentType/InvestmentType";
import { Link } from "react-router-dom";
import { LocalizationContext } from "../../context/LocalizationContext";
import {
  getAccountAgreementLink,
  getAccountDocumentRoute,
  getInvestmentGuidelinesLink,
} from "../documents/DocumentLists";
import { useAccountAllocation } from "../../hooks/useAccountAllocation";
import { PensionDocs } from "./components/pensionDocs/PensionDocs";

interface Props {
  account?: InvestmentAccount;
}

/* TODO_PEN: Remove docs from account page like in app? Check with leg first */
export function Docs({ account }: Props) {
  const localizationContext = useContext(LocalizationContext);
  const accountAllocation = useAccountAllocation(account?.accountId);

  if (!accountAllocation) {
    return null;
  }
  return (
    <section className="account-page-docs">
      <h2>
        <FormattedMessage id="accountPage.docs.header" />
      </h2>
      <CardList>
        {account && localizationContext.state.country ? (
          <Fragment>
            <div className="account-page-card-body">
              <>
                {account.type !== AccountTypeEnum.KF_SWE &&
                  !isPensionAccountType(account.type) && (
                    <div className="account-page-doc">
                      <LysaLink
                        component={Link}
                        to={getAccountAgreementLink(
                          account.accountId,
                          account.type,
                          localizationContext.state.country
                        )}
                      >
                        <FormattedMessage id="accountPage.docs.customer" />{" "}
                        <AccountType account={account} long />
                      </LysaLink>
                    </div>
                  )}
                <div className="account-page-doc">
                  <LysaLink
                    component={Link}
                    to={getInvestmentGuidelinesLink(
                      account.accountId,
                      account.type,
                      localizationContext.state.country
                    )}
                  >
                    <FormattedMessage id="accountPage.docs.guidelines" /> -{" "}
                    {accountAllocation.takenRisk} %{" "}
                    <FormattedMessage id="accountPage.docs.stocks" />,{" "}
                    {100 - accountAllocation.takenRisk} %{" "}
                    <FormattedMessage id="accountPage.docs.bonds" />,{" "}
                    <InvestmentType
                      capitalize
                      investmentType={accountAllocation.investmentType}
                    />
                  </LysaLink>
                </div>
                {isPensionAccountType(account.type) && (
                  <PensionDocs
                    accountId={account.accountId}
                    accountType={account.type}
                  />
                )}
              </>
            </div>
            <CardBottom>
              <Link
                to={getAccountDocumentRoute(
                  account.accountId,
                  account.type,
                  localizationContext.state.country
                )}
              >
                <FormattedMessage id="accountPage.docs.show" />
              </Link>
            </CardBottom>
          </Fragment>
        ) : null}
      </CardList>
    </section>
  );
}
