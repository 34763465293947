import { InvestmentAccount } from "../data/dataAccounts";
import {
  InvestmentAccountQuestions,
  InvestmentAdviseAccount,
} from "../data/dataInvestments";
import { createContext } from "./CreateContext";

export type AccountPreferences = InvestmentAccount &
  Omit<InvestmentAdviseAccount, keyof InvestmentAccountQuestions> &
  Partial<InvestmentAccountQuestions>;

export type AccountsState = {
  preferenceAccounts: AccountPreferences[];
};

export const [AccountsContextProvider, useAccountsContext] =
  createContext<AccountsState>("AccountsContext", {
    preferenceAccounts: [],
  });
