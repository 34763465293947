import { API, cache, encode } from "@lysaab/ui-2";
import {
  InvestmentAccountId,
  AccountType,
  SavingsAccountId,
} from "./dataAccounts";
import { EligibilityResponse } from "./dataInvestments";
import { dataTransactions, Filter, Transaction } from "./dataTransactions";
import { LegalEntityId } from "./dataUser";

enum CustomerType {
  PRIVATE = "PRIVATE",
  COMPANY = "COMPANY",
}

interface BaseClosedAccount {
  closed: string;
  name: string;
  legalEntityId: LegalEntityId;
  created: string;
  country: string;
  customerType: CustomerType;
}

export interface ClosedAccount extends BaseClosedAccount {
  accountId: InvestmentAccountId;
  type: AccountType;
}

export interface ClosedSavingsAccount extends BaseClosedAccount {
  accountId: SavingsAccountId;
}

export enum CloseCustomerScenario {
  CLOSE = "CLOSE",
  ECONOMIC_SITUATION = "ECONOMIC_SITUATION",
  FATCA = "FATCA",
}

export type CloseDownToken = string & { readonly isCloseDownToken: true };

interface CloseDownTokenResponse {
  token: CloseDownToken;
}

export const dataProfile = {
  closeInvestmentAccount: (accountId: InvestmentAccountId) => {
    return API.delete<EligibilityResponse>(
      encode`/profile/close/account/${accountId}`
    ).then((response) => {
      cache.clear();
      return response;
    });
  },

  closeSavingsAccount: (
    accountId: SavingsAccountId,
    externalBankAccount?: string
  ) => {
    return API.delete<void>(encode`/profile/close/savings-account`, {
      accountId,
      externalBankAccount,
    }).then((response) => {
      cache.clear();
      return response;
    });
  },

  getClosedAccounts: () => {
    return API.get<ClosedAccount[]>("/profile/close/accounts");
  },

  getClosedSavingsAccounts: () => {
    return API.get<ClosedSavingsAccount[]>("/profile/close/savings-accounts");
  },

  getClosedAccountsTransactions: (filter: Filter) => {
    const params = dataTransactions.filterToQueryParameters(filter);
    return API.get<Transaction[]>(
      `/profile/close/transaction?${params.toString()}`
    );
  },

  closeCustomer: (
    emailTaxInfo: boolean,
    token: CloseDownToken,
    scenario: CloseCustomerScenario,
    externalBankAccount?: string
  ) => {
    return API.delete(`/profile/close/customer`, {
      emailTaxInfo,
      externalBankAccount,
      token,
      scenario,
    });
  },

  getClosedownToken: () => {
    return API.get<CloseDownTokenResponse>(
      `/profile/close/customer/closedown-token`
    );
  },
};
