import { Button, Slider, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { useIntl } from "react-intl";
import { DEFAULT_RISK_DEVIATION, useTransfer } from "../TransferContext";
import { GlidepathGraph } from "../components/glidepathGraph/GlidepathGraph";
import { useUser } from "../../../../../context/UserContext";
import { getUserAge } from "../utils/userAge";
import { VoidFunctionComponent, useEffect, useState } from "react";
import { LegalEntityType } from "../../../../../data/dataLogin";
import { LysaCountry } from "@lysaab/shared";
import {
  GlidepathDataPoint,
  dataInvestments,
} from "../../../../../data/dataInvestments";

import "./RiskDeviation.scss";
import { useHistory } from "react-router";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";

interface GraphData {
  glidePath: GlidepathDataPoint[];
  age: number;
  withdrawalAge: number;
}

export const RiskDeviation: VoidFunctionComponent = () => {
  const intl = useIntl();
  const [transfer, setTransfer] = useTransfer();
  const history = useHistory();
  const user = useUser();
  const age = getUserAge(user.tin);
  const [glidepath, setGlidepath] = useState<GraphData>({
    age: age ?? 0,
    withdrawalAge: transfer.withdrawalAge,
    glidePath: [],
  });

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  useEffect(() => {
    if (
      !age ||
      user.legalEntityType !== LegalEntityType.PERSON ||
      user.country !== LysaCountry.SWEDEN
    ) {
      return;
    }

    dataInvestments
      .getPensionGlidepath(
        age,
        transfer.withdrawalAge,
        transfer.withdrawalMonths,
        transfer.takenRiskDeviation
      )
      .then((response) => {
        /**
         * For smoother lines we only need first, rebalance start, rebalance end and last.
         */
        const firstTakenRisk = response.glidePath[0].takenRisk;
        const indexForRebalanceStart = response.glidePath.reduceRight(
          (foundIndex, element, index) => {
            if (foundIndex === -1 && element.takenRisk === firstTakenRisk) {
              return index;
            }
            return foundIndex;
          },
          -1
        );

        const first = response.glidePath[0];
        const rebalanceStart =
          response.glidePath[Math.max(indexForRebalanceStart, 0)];
        const rebalanceEnd = response.glidePath[response.glidePath.length - 2];
        const last = response.glidePath[response.glidePath.length - 1];

        const smoothGlidepath = [first, rebalanceStart, rebalanceEnd, last];

        setGlidepath({
          glidePath: smoothGlidepath,
          age,
          withdrawalAge: transfer.withdrawalAge,
        });
      });
  }, [
    age,
    transfer.withdrawalAge,
    transfer.withdrawalMonths,
    transfer.takenRiskDeviation,
    user.country,
    user.legalEntityType,
    user.tin,
  ]);

  if (!age) {
    return null;
  }

  return (
    <article className="transfer-pension-risk-deviation">
      <Typography type="h2">
        <TranslatedText id="sweden.transfer-pension.risk-deviation.header" />
      </Typography>
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.risk-deviation.ingress" />
      </Typography>
      <section className="glide-path">
        <GlidepathGraph
          glidepath={glidepath.glidePath}
          age={glidepath.age}
          withdrawalAge={glidepath.withdrawalAge}
        />
      </section>
      <Slider
        label={
          <div className="slider-label">
            <span>
              {intl.formatMessage({
                id: "sweden.transfer-pension.risk-deviation.slider.label",
              })}
            </span>
            {
              <strong>{`${transfer.takenRiskDeviation >= 1 ? "+" : ""} ${
                transfer.takenRiskDeviation
              } %`}</strong>
            }
          </div>
        }
        min={-20}
        max={20}
        value={transfer.takenRiskDeviation}
        onChange={(value) => {
          setTransfer({ takenRiskDeviation: value });
        }}
      />
      <section className="slider-scale">
        <span>-20%</span>
        <span>0%</span>
        <span>+20</span>
      </section>
      <ul className="list">
        <li>
          <TranslatedText id="sweden.transfer-pension.risk-deviation.list-item-1" />
        </li>
        <li>
          <TranslatedText id="sweden.transfer-pension.risk-deviation.list-item-2" />
        </li>
      </ul>
      <Button
        block
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.advice.save",
        })}
        onClick={history.goBack}
      />
      <Button
        block
        variant="secondary"
        type="submit"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.advice.cancel",
        })}
        onClick={() => {
          setTransfer({ takenRiskDeviation: DEFAULT_RISK_DEVIATION });
          history.goBack();
        }}
      />
    </article>
  );
};
