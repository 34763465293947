import { Story } from "@lysaab/ui-2";
import { useCallback, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { generatePath, useLocation } from "react-router";
import { Route } from "../../../../../../components/route/Route";
import { Switch } from "../../../../../../components/route/Switch";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../../../../hooks/useSafeNavigation";
import { useStoryValues } from "../../../../../../hooks/useStoryValues";
import { DEPOSITS_OVERVIEW_URL } from "../../../../../../pages/deposits/overview/Recommendation";
import { PageStripped } from "../../../../../../pages/PageStripped";
import { AccountLoadingWrapper } from "./accountLoading/AccountLoadingWrapper";
import { ExternalAccountSelection } from "./externalAccountSelection/ExternalAccountSelection";
import {
  AutogiroDepositContext,
  AutogiroDepositContextProvider,
  AutogiroDepositState,
} from "./AutogiroDepositContext";
import { BankSelectionWrapper } from "./bankSelection/BankSelectionWrapper";
import { AutogiroDepositDone } from "./done/AutogiroDepositDone";
import { KlarnaAccountSelection } from "./klarnaAccountSelection/KlarnaAccountSelection";
import { LysaAccountSelection } from "./lysaAccountSelection/LysaAccountSelection";
import { AmountSelection } from "./amountSelection/AmountSelection";
import { OVERVIEW_PAGE_URL } from "../../../../../../pages/overview/OverviewPage";
import { Variant } from "@lysaab/shared";
import { experimentKeys } from "../../../../../../experimentConfig";
import { TinkBankSelectionWrapper } from "./bankSelection/TinkSelectionWrapper";

export const AUTOGIRO_DEPOSIT_PAGE_URL = "/deposits/autogiro";

export const BASE_ROUTES = {
  LYSA_ACCOUNT_SELECTION: `${AUTOGIRO_DEPOSIT_PAGE_URL}`,
  EXTERNAL_ACCOUNT_SELECTION: `${AUTOGIRO_DEPOSIT_PAGE_URL}/external-account-selection`,
  BANK_SELECTION: `${AUTOGIRO_DEPOSIT_PAGE_URL}/bank-selection`,
  ACCOUNT_LOADING: `${AUTOGIRO_DEPOSIT_PAGE_URL}/account-loading`,
  KLARNA_ACCOUNT_SELECTION: `${AUTOGIRO_DEPOSIT_PAGE_URL}/account-selection`,
  AMOUNT_SELECTION: `${AUTOGIRO_DEPOSIT_PAGE_URL}/amount-selection`,
  DONE: `${AUTOGIRO_DEPOSIT_PAGE_URL}/done`,
};

const messages = defineMessages({
  header: {
    id: "sweden.deposits.autogiro.story.header",
  },
  ariaProgressLabel: {
    id: "sweden.deposits.autogiro.story.ariaProgressLabel",
  },
});

export function AutogiroDepositPageInternal() {
  const location = useLocation();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const autogiroDepositContext = useContext(AutogiroDepositContext);

  const [currentIndex, routes, progress, storyLength] =
    useStoryValues(BASE_ROUTES);

  const accountLoadingNext = useCallback(() => {
    safeNavigation(routes.KLARNA_ACCOUNT_SELECTION);
  }, [routes.KLARNA_ACCOUNT_SELECTION, safeNavigation]);

  const onBack = (currentIndex: number) => {
    if (currentIndex === 0) {
      safeNavigation(getNavLink(DEPOSITS_OVERVIEW_URL));
      return;
    } else if (location.pathname === routes.KLARNA_ACCOUNT_SELECTION) {
      safeNavigation(routes.EXTERNAL_ACCOUNT_SELECTION);
      return;
    } else if (location.pathname === routes.AMOUNT_SELECTION) {
      if (autogiroDepositContext.state.klarnaAccounts) {
        safeNavigation(routes.KLARNA_ACCOUNT_SELECTION);
      } else {
        safeNavigation(routes.EXTERNAL_ACCOUNT_SELECTION);
      }
      return;
    } else {
      safeNavigation(generatePath(Object.values(routes)[currentIndex - 1]));
      return;
    }
  };

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex + 1,
            total: storyLength,
          })
        }
        header={intl.formatMessage(messages.header)}
        onExit={() => {
          safeNavigation(getNavLink(OVERVIEW_PAGE_URL));
        }}
        showClose={true}
        showBack={location.pathname !== routes.DONE}
        onBack={() => onBack(currentIndex)}
        transitionKey={currentIndex.toString()}
        progress={progress}
      >
        <Switch
          location={location}
          {...{
            order: currentIndex,
          }}
        >
          <Route path={routes.LYSA_ACCOUNT_SELECTION} exact>
            <LysaAccountSelection
              next={() => {
                safeNavigation(routes.EXTERNAL_ACCOUNT_SELECTION);
              }}
            />
          </Route>
          <Route path={routes.EXTERNAL_ACCOUNT_SELECTION} exact>
            <ExternalAccountSelection
              next={() => {
                safeNavigation(routes.AMOUNT_SELECTION);
              }}
            />
          </Route>
          <Route
            path={routes.BANK_SELECTION}
            exact
            render={() => (
              <>
                <Variant propertyKey={experimentKeys.TINK_ENABLED} value="true">
                  <TinkBankSelectionWrapper
                    next={() => safeNavigation(routes.ACCOUNT_LOADING)}
                  />
                </Variant>
                <Variant
                  propertyKey={experimentKeys.TINK_ENABLED}
                  value="false"
                >
                  <BankSelectionWrapper
                    next={() => safeNavigation(routes.ACCOUNT_LOADING)}
                  />
                </Variant>
              </>
            )}
          />
          <Route
            path={routes.ACCOUNT_LOADING}
            exact
            render={() => <AccountLoadingWrapper next={accountLoadingNext} />}
          />
          <Route
            path={routes.KLARNA_ACCOUNT_SELECTION}
            exact
            render={() => (
              <KlarnaAccountSelection
                next={() => safeNavigation(routes.AMOUNT_SELECTION)}
              />
            )}
          />
          <Route
            path={routes.AMOUNT_SELECTION}
            exact
            render={() => (
              <AmountSelection
                next={(state: AutogiroDepositState) =>
                  safeNavigation({ pathname: routes.DONE, state })
                }
              />
            )}
          />
          <Route path={routes.DONE} exact>
            <AutogiroDepositDone />
          </Route>
        </Switch>
      </Story>
    </PageStripped>
  );
}

export const AutogiroDepositPage = () => (
  <AutogiroDepositContextProvider>
    <AutogiroDepositPageInternal />
  </AutogiroDepositContextProvider>
);
