import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  VoidFunctionComponent,
} from "react";
import { Spinner, Story, useBeforeunload, WorldCountry } from "@lysaab/ui-2";
import {
  EMPTY_PEP_STATE,
  IsPepPage,
  PepContext,
  PepContextProvider,
  PepRolePage,
  PepStatusPage,
  PepType,
  RelationPage,
} from "@lysaab/lysa-pep";
import { defineMessages, useIntl } from "react-intl";
import {
  useHistory,
  useLocation,
  generatePath,
  matchPath,
} from "react-router-dom";
import { Route } from "../../components/route/Route";
import { Switch } from "../../components/route/Switch";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../hooks/useSafeNavigation";
import { OVERVIEW_PAGE_URL } from "../overview/OverviewPage";
import { PageStripped } from "../PageStripped";
import { VerifyEmail } from "./pages/email/VerifyEmail";
import { Fatca } from "./pages/fatca/Fatca";
import { AccountsKyc } from "./pages/accountsKyc/AccountsKyc";
import { LocalizationContext } from "../../context/LocalizationContext";
import { SituationPerson } from "./pages/situation/SituationPerson";
import { SituationCorporation } from "./pages/situation/SituationCorporation";
import { IsPepPageWrapper } from "./pages/pep/IsPepPageWrapper";
import { AccountsAllocation } from "./pages/accountsAllocation/AccountsAllocation";
import { KycPerson } from "./pages/customerKyc/KycPerson";
import { AccessGuardRoute, YearlyReviewReturnState } from "./AccessGuardRoute";
import { KycCorporation } from "./pages/customerKyc/KycCorporation";
import { PepOverview } from "./pages/pep/PepOverview";
import { useFeatureContext } from "../../context/FeatureContext";
import { AccountSituation } from "../../pageComponents/accountSituation/AccountSituation";
import { Done } from "./pages/done/Done";
import { CrsProps } from "./pages/crs/Crs";
import { BASE_ROUTES as REVIEW_ACCOUNT_ROUTES } from "../reviewAccount/ReviewAccountStory";
import { Citizenship } from "./pages/citizenship/Citizenship";
import { dataAccounts } from "../../data/dataAccounts";
import {
  dataInvestments,
  isSavingsHorizonLength,
  isNeedEarlierProbability,
  isValidInvestmentAccountQuestions,
} from "../../data/dataInvestments";
import {
  getNextPepIsPep,
  getNextPepIsPepWrapper,
  getNextPepRole,
  getRoutePepOverview,
  navigateIsPepFromPepOverview,
} from "./RoutePepUtils";
import "./YearlyReviewStory.scss";
import { useIsPerson } from "../../hooks/useIsPerson";
import {
  isValidFatca,
  YearlyReviewAccessGuard,
  YearlyReviewContextProvider,
} from "./contexts/YearlyReviewContext";
import {
  useMultiPepContext,
  MultiPepStateContextProvider,
} from "./contexts/MultiPepContext";
import { EligibilityContextProvider } from "../../context/EligibilityContext";
import { useNavigateToReviewAccount } from "./hooks/useNavigateToReviewAccount";
import {
  AccountPreferences,
  AccountsContextProvider,
  useAccountsContext,
} from "../../context/AccountsContext";
import { ReviewAccountContextProvider } from "../reviewAccount/ReviewAccountContext";
import { UpdateInvestmentAccountComposition } from "../../pageComponents/accountsAllocation/accountAdviseCard/AccountAdviceCard";
import {
  EligibilityAccessGuard,
  isValidEconomy,
} from "../../components/eligibilityAccessGuard/EligibilityAccessGuard";
import { accountRequiresKyc } from "../../data/dataKyc";

export const YEARLY_REVIEW_URL = "/yearly-review";

export const BASE_ROUTES = {
  EMAIL: `${YEARLY_REVIEW_URL}/`,
  FATCA: `${YEARLY_REVIEW_URL}/fatca`,
  CRS: `${YEARLY_REVIEW_URL}/crs`,
  CITIZENSHIP: `${YEARLY_REVIEW_URL}/citizenship`,
  CUSTOMER_KYC: `${YEARLY_REVIEW_URL}/customer-kyc`,
  ACCOUNTS_KYC: `${YEARLY_REVIEW_URL}/accounts-kyc`,
  PEP_IS_PEP: `${YEARLY_REVIEW_URL}/is-pep`,
  PEP_RELATION: `${YEARLY_REVIEW_URL}/pep-relation`,
  PEP_STATUS: `${YEARLY_REVIEW_URL}/pep-status`,
  PEP_ROLE: `${YEARLY_REVIEW_URL}/pep-role`,
  PEP_OVERVIEW: `${YEARLY_REVIEW_URL}/pep-overview`,
  SITUATION: `${YEARLY_REVIEW_URL}/situation`,
  ACCOUNT_SITUATION: `${YEARLY_REVIEW_URL}/accounts-situation/:accountId`,
  ACCOUNTS_ALLOCATION: `${YEARLY_REVIEW_URL}/accounts-allocation`,
  DONE: `${YEARLY_REVIEW_URL}/done`,
};

const messages = defineMessages({
  header: {
    id: "yearly-review.story.header",
  },
  ariaProgressLabel: {
    id: "yearly-review.story.ariaProgressLabel",
  },
});

interface Props {
  crsComponent?: VoidFunctionComponent<CrsProps>;
}

export const YearlyReviewStoryInstance: VoidFunctionComponent<Props> = ({
  crsComponent: CrsComponent,
}) => {
  const location = useLocation();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const history = useHistory<YearlyReviewReturnState | undefined>();
  const isPerson = useIsPerson();
  const pepContext = useContext(PepContext);
  const [featureState] = useFeatureContext();
  const [multiPepState, setMultiPepState] = useMultiPepContext();
  const [accountsState, setAccountsState] = useAccountsContext();
  const navigateToReviewAccount = useNavigateToReviewAccount();
  const localizationContext = useContext(LocalizationContext);

  // Summarises the usable routes
  const ROUTE_PATHS = Object.entries(BASE_ROUTES).reduce(
    (ROUTES, [key, path]) => ({ ...ROUTES, [key]: getNavLink(path) }),
    {} as typeof BASE_ROUTES
  );

  // Contains all used routes (paths can be used multiple times)
  const ACTUAL_ROUTES = useMemo(() => {
    return Object.entries(BASE_ROUTES).reduce((routes, [key, path]) => {
      if (path === BASE_ROUTES.ACCOUNT_SITUATION) {
        return {
          ...routes,
          ...accountsState.preferenceAccounts.reduce(
            (situationRoutes, account, index) => {
              return {
                ...situationRoutes,
                [`${key}_${index}`]: generatePath(path, {
                  accountId: account.accountId,
                }),
              };
            },
            {}
          ),
        };
      }
      return { ...routes, [key]: path };
    }, {} as { [key: string]: string });
  }, [accountsState.preferenceAccounts]);

  const currentIndex = Object.values(ACTUAL_ROUTES).findIndex((path) => {
    return (
      matchPath(location.pathname, {
        path: getNavLink(path),
        exact: true,
      }) !== null
    );
  });

  const storyProgress =
    (100 / Object.values(ACTUAL_ROUTES).length) * (currentIndex + 1);

  const storyLength = Object.values(ACTUAL_ROUTES).length;

  useEffect(() => {
    // Here we fetch all accounts
    // When entering a substory we pass the accounts into that substory
    // When returning back here we don't want to fetch the accounts again,
    // because that would override any modified data
    // The accounts that get modified in the substory will be returned back to
    // this story using history state. This is handled in DirectAccessGuard
    if (
      accountsState.preferenceAccounts.length ||
      history.location.state?.accountsState
    ) {
      return;
    }

    Promise.all([
      dataAccounts.getAccounts(),
      dataInvestments.getAdviseAccounts(),
    ]).then(([accounts, adviseAccounts]) => {
      const preferenceAccounts = accounts
        .filter(accountRequiresKyc)
        .reduce<AccountPreferences[]>((accounts, account) => {
          const adviseAccount = adviseAccounts.find(
            (adviceAccount) => adviceAccount.accountId === account.accountId
          );
          if (
            adviseAccount &&
            isValidInvestmentAccountQuestions(adviseAccount)
          ) {
            return [
              ...accounts,
              {
                ...account,
                ...adviseAccount,
                needEarlier: isNeedEarlierProbability(adviseAccount.needEarlier)
                  ? adviseAccount.needEarlier
                  : undefined,
                savingsHorizon: isSavingsHorizonLength(
                  adviseAccount.savingsHorizon
                )
                  ? adviseAccount.savingsHorizon
                  : undefined,
              },
            ];
          }
          return accounts;
        }, []);
      setAccountsState({ preferenceAccounts });
    });
  }, [accountsState, setAccountsState, history.location.state]);

  useBeforeunload((event) => {
    event.returnValue = "";
  });

  const goToPreviousPep = useCallback(() => {
    const pepStates = multiPepState.pepStates;
    const currentIndex = multiPepState.index;

    if (!pepStates) {
      throw new Error("YearlyReviewContext doesn't contain any pep states");
    }

    let newIndex = currentIndex - 1;

    while (newIndex >= -1) {
      if (newIndex === -1) {
        return safeNavigation(ROUTE_PATHS.PEP_IS_PEP);
      }

      if (
        typeof pepStates[newIndex].type !== "undefined" &&
        pepStates[newIndex].type !== PepType.NOT_PEP
      ) {
        pepContext.setState({
          ...EMPTY_PEP_STATE,
          ...pepStates[newIndex],
        });
        setMultiPepState({ index: newIndex });

        safeNavigation(ROUTE_PATHS.PEP_ROLE);
        break;
      }

      newIndex--;
    }
  }, [
    ROUTE_PATHS.PEP_IS_PEP,
    ROUTE_PATHS.PEP_ROLE,
    pepContext,
    safeNavigation,
    setMultiPepState,
    multiPepState.index,
    multiPepState.pepStates,
  ]);

  const onBack = useCallback(() => {
    const { index, pepStates } = multiPepState;
    const { type: pepType } = pepContext.state;
    const currentRoute = getNavLink(Object.values(ACTUAL_ROUTES)[currentIndex]);
    const previousRoute = getNavLink(
      Object.values(ACTUAL_ROUTES)[currentIndex - 1]
    );

    switch (currentRoute) {
      case ROUTE_PATHS.SITUATION: {
        if (index === -1) {
          safeNavigation(ROUTE_PATHS.PEP_IS_PEP);
        } else {
          typeof pepStates !== "undefined" && pepStates.length === 1
            ? safeNavigation(ROUTE_PATHS.PEP_ROLE)
            : safeNavigation(ROUTE_PATHS.PEP_OVERVIEW);
        }
        return;
      }
      case ROUTE_PATHS.PEP_STATUS: {
        if (index === 0) {
          typeof pepType !== "undefined" && pepType === PepType.ME
            ? safeNavigation(ROUTE_PATHS.PEP_IS_PEP)
            : safeNavigation(ROUTE_PATHS.PEP_RELATION);
        } else {
          goToPreviousPep();
        }
        return;
      }
      case ROUTE_PATHS.PEP_RELATION: {
        index === 0
          ? safeNavigation(ROUTE_PATHS.PEP_IS_PEP)
          : goToPreviousPep();
        return;
      }
      case ROUTE_PATHS.PEP_OVERVIEW: {
        safeNavigation(ROUTE_PATHS.ACCOUNTS_KYC);
        return;
      }
      case ROUTE_PATHS.ACCOUNTS_ALLOCATION: {
        safeNavigation(
          generatePath(ROUTE_PATHS.ACCOUNT_SITUATION, {
            accountId:
              accountsState.preferenceAccounts[
                accountsState.preferenceAccounts.length - 1
              ].accountId,
          })
        );
        return;
      }
    }
    safeNavigation(previousRoute);
  }, [
    ACTUAL_ROUTES,
    ROUTE_PATHS,
    currentIndex,
    accountsState.preferenceAccounts,
    goToPreviousPep,
    pepContext.state,
    safeNavigation,
    multiPepState,
  ]);

  if (!localizationContext.state.country) {
    return <Spinner />;
  }

  return (
    <PageStripped className="yearly-review-story">
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex + 1,
            total: storyLength,
          })
        }
        header={intl.formatMessage(messages.header)}
        progress={storyProgress}
        showBack={currentIndex !== 0 && currentIndex !== storyLength - 1}
        showClose={!featureState.yearlyReviewLockRoutes}
        transitionKey={currentIndex.toString()}
        onExit={() => {
          safeNavigation(getNavLink(OVERVIEW_PAGE_URL));
        }}
        onBack={onBack}
      >
        <Switch
          location={location}
          {...{
            order: currentIndex,
          }}
        >
          <Route exact path={ROUTE_PATHS.EMAIL}>
            <VerifyEmail
              next={() =>
                isPerson
                  ? safeNavigation(ROUTE_PATHS.FATCA)
                  : safeNavigation(ROUTE_PATHS.CUSTOMER_KYC)
              }
            />
          </Route>
          <AccessGuardRoute exact path={ROUTE_PATHS.FATCA}>
            <Fatca
              next={() =>
                CrsComponent !== undefined
                  ? safeNavigation(ROUTE_PATHS.CRS)
                  : safeNavigation(ROUTE_PATHS.CUSTOMER_KYC)
              }
            />
          </AccessGuardRoute>
          <AccessGuardRoute exact path={ROUTE_PATHS.CRS}>
            <YearlyReviewAccessGuard
              fallbackRoute={ROUTE_PATHS.FATCA}
              isValid={isValidFatca}
            >
              {CrsComponent && (
                <CrsComponent
                  next={() => safeNavigation(ROUTE_PATHS.CITIZENSHIP)}
                />
              )}
            </YearlyReviewAccessGuard>
          </AccessGuardRoute>
          <AccessGuardRoute exact path={ROUTE_PATHS.CITIZENSHIP}>
            <Citizenship
              next={() => safeNavigation(ROUTE_PATHS.CUSTOMER_KYC)}
            />
          </AccessGuardRoute>
          <AccessGuardRoute exact path={ROUTE_PATHS.CUSTOMER_KYC}>
            <YearlyReviewAccessGuard
              fallbackRoute={ROUTE_PATHS.FATCA}
              isValid={isValidFatca}
            >
              {isPerson ? (
                <KycPerson
                  next={() => safeNavigation(ROUTE_PATHS.ACCOUNTS_KYC)}
                />
              ) : (
                <KycCorporation
                  next={() => safeNavigation(ROUTE_PATHS.ACCOUNTS_KYC)}
                />
              )}
            </YearlyReviewAccessGuard>
          </AccessGuardRoute>
          <AccessGuardRoute exact path={ROUTE_PATHS.ACCOUNTS_KYC}>
            <AccountsKyc next={() => safeNavigation(ROUTE_PATHS.PEP_IS_PEP)} />
          </AccessGuardRoute>
          <AccessGuardRoute path={ROUTE_PATHS.PEP_IS_PEP}>
            {multiPepState.isEditing ? (
              <>
                {(multiPepState.pepStates?.length ?? 0) > 1 && (
                  <h2>{pepContext.state.ownerName}</h2>
                )}
                <IsPepPage
                  next={() =>
                    safeNavigation(
                      getNextPepIsPep(
                        ROUTE_PATHS,
                        [multiPepState, setMultiPepState],
                        pepContext
                      )
                    )
                  }
                />
              </>
            ) : (
              <IsPepPageWrapper
                next={() =>
                  safeNavigation(
                    getNextPepIsPepWrapper(
                      ROUTE_PATHS,
                      [multiPepState, setMultiPepState],
                      pepContext
                    )
                  )
                }
              />
            )}
          </AccessGuardRoute>
          <AccessGuardRoute path={ROUTE_PATHS.PEP_RELATION}>
            {(multiPepState.pepStates?.length ?? 0) > 1 && (
              <h2>{pepContext.state.ownerName}</h2>
            )}
            <RelationPage next={() => safeNavigation(ROUTE_PATHS.PEP_STATUS)} />
          </AccessGuardRoute>
          <AccessGuardRoute path={ROUTE_PATHS.PEP_STATUS}>
            {(multiPepState.pepStates?.length ?? 0) > 1 && (
              <h2>{pepContext.state.ownerName}</h2>
            )}
            <PepStatusPage
              countryCode={
                localizationContext.state.country as unknown as WorldCountry
              }
              countryName={
                intl.formatDisplayName(localizationContext.state.country, {
                  type: "region",
                }) || ""
              }
              next={() => safeNavigation(ROUTE_PATHS.PEP_ROLE)}
              language={localizationContext.state.language}
            />
          </AccessGuardRoute>
          <AccessGuardRoute path={ROUTE_PATHS.PEP_ROLE}>
            {(multiPepState.pepStates?.length ?? 0) > 1 && (
              <h2>{pepContext.state.ownerName}</h2>
            )}
            <PepRolePage
              next={() =>
                safeNavigation(
                  getNextPepRole(
                    ROUTE_PATHS,
                    [multiPepState, setMultiPepState],
                    pepContext
                  )
                )
              }
              language={localizationContext.state.language}
            />
          </AccessGuardRoute>
          <AccessGuardRoute path={ROUTE_PATHS.PEP_OVERVIEW}>
            <PepOverview
              next={() =>
                safeNavigation(getRoutePepOverview(ROUTE_PATHS, multiPepState))
              }
              navigateIsPep={navigateIsPepFromPepOverview(
                ROUTE_PATHS,
                [multiPepState, setMultiPepState],
                pepContext,
                safeNavigation
              )}
            />
          </AccessGuardRoute>
          <AccessGuardRoute path={ROUTE_PATHS.SITUATION}>
            {isPerson ? (
              <SituationPerson
                next={() =>
                  // navigate to first account situation
                  safeNavigation(
                    generatePath(ROUTE_PATHS.ACCOUNT_SITUATION, {
                      accountId: accountsState.preferenceAccounts[0].accountId,
                    })
                  )
                }
              />
            ) : (
              <SituationCorporation
                next={() =>
                  // navigate to first account situation
                  safeNavigation(
                    generatePath(ROUTE_PATHS.ACCOUNT_SITUATION, {
                      accountId: accountsState.preferenceAccounts[0].accountId,
                    })
                  )
                }
              />
            )}
          </AccessGuardRoute>
          {accountsState.preferenceAccounts.map((account, index, arr) => {
            function setAccount(account: AccountPreferences) {
              const accounts = arr;
              accounts.splice(index, 1, account);
              setAccountsState({ preferenceAccounts: accounts });
            }

            return (
              <AccessGuardRoute
                key={account.accountId}
                path={generatePath(ROUTE_PATHS.ACCOUNT_SITUATION, {
                  accountId: account.accountId,
                })}
              >
                <EligibilityAccessGuard
                  fallbackRoute={ROUTE_PATHS.SITUATION}
                  isValid={isValidEconomy}
                >
                  <AccountSituation
                    account={account}
                    setAccount={setAccount}
                    next={() => {
                      // navigate to next account situation or go to accounts allocation
                      if (index < accountsState.preferenceAccounts.length - 1) {
                        return safeNavigation(
                          generatePath(ROUTE_PATHS.ACCOUNT_SITUATION, {
                            accountId:
                              accountsState.preferenceAccounts[index + 1]
                                .accountId,
                          })
                        );
                      }
                      safeNavigation(ROUTE_PATHS.ACCOUNTS_ALLOCATION);
                    }}
                  />
                </EligibilityAccessGuard>
              </AccessGuardRoute>
            );
          })}
          <AccessGuardRoute path={ROUTE_PATHS.ACCOUNTS_ALLOCATION}>
            <YearlyReviewAccessGuard
              fallbackRoute={ROUTE_PATHS.FATCA}
              isValid={isValidFatca}
            >
              <EligibilityAccessGuard
                fallbackRoute={ROUTE_PATHS.SITUATION}
                isValid={isValidEconomy}
              >
                <AccountsAllocation
                  next={() => safeNavigation(ROUTE_PATHS.DONE)}
                  navigateReviewRiskWarning={(
                    reviewAccount: UpdateInvestmentAccountComposition
                  ) =>
                    navigateToReviewAccount(
                      REVIEW_ACCOUNT_ROUTES.RISK_WARNING,
                      reviewAccount
                    )
                  }
                  navigateReview={(
                    reviewAccount: UpdateInvestmentAccountComposition
                  ) => {
                    const hasChangedEsgValues =
                      typeof reviewAccount.newAdvice.esgResult.esgBestMatch !==
                      "undefined";

                    if (hasChangedEsgValues) {
                      navigateToReviewAccount(
                        REVIEW_ACCOUNT_ROUTES.CONFIRM_ESG_UPDATE,
                        reviewAccount
                      );
                    } else {
                      navigateToReviewAccount(
                        REVIEW_ACCOUNT_ROUTES.REVIEW_HORIZON_ADVICE,
                        reviewAccount
                      );
                    }
                  }}
                />
              </EligibilityAccessGuard>
            </YearlyReviewAccessGuard>
          </AccessGuardRoute>
          <AccessGuardRoute path={ROUTE_PATHS.DONE}>
            <Done />
          </AccessGuardRoute>
        </Switch>
      </Story>
    </PageStripped>
  );
};

export const YearlyReviewStory: VoidFunctionComponent<Props> = ({
  crsComponent,
}) => {
  return (
    <ReviewAccountContextProvider>
      <PepContextProvider>
        <MultiPepStateContextProvider>
          <YearlyReviewContextProvider>
            <AccountsContextProvider>
              <EligibilityContextProvider>
                <YearlyReviewStoryInstance crsComponent={crsComponent} />
              </EligibilityContextProvider>
            </AccountsContextProvider>
          </YearlyReviewContextProvider>
        </MultiPepStateContextProvider>
      </PepContextProvider>
    </ReviewAccountContextProvider>
  );
};
