import { Interval, IndexId } from "./data/dataPerformance";
import { AccountOrder, AccountOrderDirection } from "./data/dataAccounts";
import { LysaCountry } from "@lysaab/shared";

export const STORE_ACCOUNTS_ORDER = "accounts.order";
export const STORE_ACCOUNTS_ORDER_DIRECTION = "accounts.order.direction";
export const STORE_PERFORMANCE_INTERVAL = "performance.interval";
export const STORE_PERFORMANCE_INDEXES = "performance.indexes";
export const STORE_PERFORMANCE_CUSTOM_START_DATE = "performance.interval.start";
export const STORE_PERFORMANCE_CUSTOM_END_DATE = "performance.interval.end";
export const STORE_PERFORMANCE_SHOW_EVENTS = "performance.showEvents";
export const STORE_PREFERENCES_COUNTRY = "preferences.country";
export const CURRENT_DAY = "CURRENT_DAY";
export const YEARLY_REVIEW_FORCE_CLOSE = "YEARLY_REVIEW_FORCE_CLOSE_V3";
export const SEE_NO_EVIL = "settings.seeNoEvil";
export const VIEW_CHANGE_IN_PERCENTAGE = "settings.viewChangeInPercentage";
export const STORE_SHOW_PERFORMANCE_GRAPH = "performanceGraph.show";
export const DARK_MODE = "settings.darkMode";
export const STORE_SETTINGS_COOKIE_CONSENT_OK = "settings.cookieConsent.ok";
export const STORE_SETTINGS_COOKIE_CONSENT_ALLOW =
  "settings.cookieConsent.tracking";
export const STORE_SETTINGS_COOKIE_CONSENT_NECESSARY =
  "settings.cookieConsent.necessary";
export const STORE_SETTINGS_COOKIE_BANNER_OK = "settings.cookieBanner.ok";

export interface AccountsUsed {
  [key: string]: Date;
}

interface StoredData {
  [STORE_ACCOUNTS_ORDER]: AccountOrder;
  [STORE_ACCOUNTS_ORDER_DIRECTION]: AccountOrderDirection;
  [STORE_PERFORMANCE_INTERVAL]: Interval;
  [STORE_PERFORMANCE_INDEXES]: IndexId[];
  [STORE_PERFORMANCE_CUSTOM_START_DATE]: Date | string | null;
  [STORE_PERFORMANCE_CUSTOM_END_DATE]: Date | string | null;
  [STORE_PERFORMANCE_SHOW_EVENTS]: boolean;
  [STORE_PREFERENCES_COUNTRY]: LysaCountry | null;
  [STORE_SETTINGS_COOKIE_CONSENT_OK]: Date | null;
  [YEARLY_REVIEW_FORCE_CLOSE]: [string, string][] | null;
  [SEE_NO_EVIL]: boolean;
  [VIEW_CHANGE_IN_PERCENTAGE]: boolean;
  [STORE_SHOW_PERFORMANCE_GRAPH]: boolean | null;
  [DARK_MODE]: "SYSTEM" | "LIGHT" | "DARK";
  [STORE_SETTINGS_COOKIE_CONSENT_ALLOW]: boolean | null;
  [STORE_SETTINGS_COOKIE_CONSENT_NECESSARY]: boolean | null;
  [STORE_SETTINGS_COOKIE_BANNER_OK]: boolean | null;
}

const NAME = "lysa-web-app-v2";

const defaultValues: StoredData = {
  [STORE_ACCOUNTS_ORDER]: AccountOrder.CREATED,
  [STORE_ACCOUNTS_ORDER_DIRECTION]: AccountOrderDirection.ASCENDING,
  [STORE_PERFORMANCE_INDEXES]: [],
  [STORE_PERFORMANCE_INTERVAL]: Interval.SINCE_REGISTRATION,
  [STORE_PERFORMANCE_CUSTOM_START_DATE]: null,
  [STORE_PERFORMANCE_CUSTOM_END_DATE]: CURRENT_DAY,
  [STORE_PERFORMANCE_SHOW_EVENTS]: false,
  [STORE_PREFERENCES_COUNTRY]: null,
  [STORE_SETTINGS_COOKIE_CONSENT_OK]: null,
  [YEARLY_REVIEW_FORCE_CLOSE]: null,
  [SEE_NO_EVIL]: false,
  [VIEW_CHANGE_IN_PERCENTAGE]: false,
  [STORE_SHOW_PERFORMANCE_GRAPH]: null,
  // Disable respecting system settings while running user tests
  // [DARK_MODE]: "SYSTEM",
  [DARK_MODE]: "LIGHT",
  [STORE_SETTINGS_COOKIE_CONSENT_ALLOW]: null,
  [STORE_SETTINGS_COOKIE_CONSENT_NECESSARY]: null,
  [STORE_SETTINGS_COOKIE_BANNER_OK]: null,
};

// Only default to SYSTEM in lysa-test and on localhost for now
if (
  !window.location.hostname.includes("lysa-test") &&
  !window.location.hostname.includes("localhost")
) {
  defaultValues[DARK_MODE] = "LIGHT";
}

let storedData: StoredData = { ...defaultValues };
try {
  const storedStringData = window.localStorage.getItem(NAME);
  if (storedStringData) {
    storedData = { ...defaultValues, ...JSON.parse(storedStringData) };
  }
} catch (err) {}

export const Store = {
  getValue<T extends keyof StoredData>(key: T) {
    return storedData[key];
  },

  setValue<Key extends keyof StoredData>(key: Key, value: StoredData[Key]) {
    storedData[key] = value;
    try {
      window.localStorage.setItem(NAME, JSON.stringify(storedData));
    } catch (err) {
      //Do not log
    }
  },

  getStorage() {
    return storedData;
  },
};
