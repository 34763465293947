import { useState } from "react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, SNACKBAR_TYPES, Snackbar, Typography } from "@lysaab/ui-2";
import { dataLifePensionMove } from "../../../../../../data/dataLifePensionMove";

import "./MailingInstructions.scss";

interface Props {
  caseId: string;
  moveId?: string;
  onActionTaken?: () => void;
}

export const MailingInstructions: React.VFC<Props> = ({
  caseId,
  moveId,
  onActionTaken,
}) => {
  const intl = useIntl();
  const [sentHome, setSentHome] = useState(false);

  return (
    <div className="MailingInstructions">
      <div className="MailingInstructions__buttons">
        <Button
          component="a"
          type="button"
          download
          variant="secondary"
          icon="Download"
          target="_blank"
          block
          label={intl.formatMessage({
            id: "sweden.mailing-instructions.download.button",
          })}
          href={dataLifePensionMove.getPensionMoveApplicationUrl(
            moveId ?? caseId,
            moveId ? "move" : "case"
          )}
          onClick={() => {
            onActionTaken?.();
          }}
        />

        <Button
          type="button"
          variant="outlined"
          block
          label={intl.formatMessage({
            id: "sweden.mailing-instructions.send.button",
          })}
          disabled={sentHome}
          onClick={() => {
            dataLifePensionMove.sendHome(caseId).then(() => {
              setSentHome(true);
              onActionTaken?.();
            });
          }}
        />
      </div>

      {sentHome && (
        <Snackbar type={SNACKBAR_TYPES.SUCCESS} icon>
          <div className="MailingInstructions__sent-home">
            <FormattedMessage id="sweden.mailing-instructions.send.sent-home" />
          </div>
        </Snackbar>
      )}

      <div>
        <Typography type="h3">
          <FormattedMessage id="sweden.mailing-instructions.header" />
        </Typography>
        <Typography>
          <FormattedMessage id="sweden.mailing-instructions.description" />
        </Typography>
      </div>

      <div className="MailingInstructions__address">
        <Typography component="span">
          <FormattedMessage id="sweden.mailing-instructions.address.name" />
        </Typography>
        <Typography component="span">
          <FormattedMessage id="sweden.mailing-instructions.address.street" />
        </Typography>
        <Typography component="span">
          <FormattedMessage id="sweden.mailing-instructions.address.zip-city" />
        </Typography>
        <Typography component="span">
          <FormattedMessage id="sweden.mailing-instructions.address.free-mailing-label" />
        </Typography>
        <Typography component="span">
          <FormattedMessage id="sweden.mailing-instructions.address.free-mailing-number" />
        </Typography>
      </div>
    </div>
  );
};
