import { useCallback, useEffect, useMemo, useRef } from "react";
import * as React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import {
  BankIDStatus,
  FailedHintCode,
  OrderRef,
  PendingHintCode,
  useBankId,
  useDisablePollInBackground,
} from "@lysaab/ui-2";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { ROUTES } from "./WithdrawalKfRequestPage";
import { dataDanica } from "../../../../../../data/dataDanica";
import { IntlShape, useIntl } from "react-intl";
import { parse, stringify } from "query-string";
import {
  signFailedMessages,
  signPendingMessages,
  signingMessages,
} from "./BankIdMessages";
import { Location } from "history";

interface Params {
  signingId: string;
  userId: string;
}

interface SearchParams {
  orderRef?: OrderRef;
  at?: string;
}

// QR code is included in poll here, so need to poll each second
const POLL_TIMER = 1000;

export const Sign: React.FunctionComponent = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<Params>();
  const { signingId, userId } = params;
  const history = useHistory();
  const location = useLocation();
  const { orderRef, autoStartToken } = getParamsFromUrl(location);
  const latestQrCode = useRef<string | undefined>(undefined);

  const onComplete = useCallback(
    // TODO: Type this
    (_: any, reset: any) => {
      history.replace(
        getNavLink(ROUTES.SIGNERS.replace(":signingId", signingId))
      );
      reset();
    },
    [signingId, history]
  );

  const qrCodePollFn = useCallback(
    () =>
      latestQrCode.current
        ? Promise.resolve({ code: latestQrCode.current })
        : Promise.resolve(undefined),
    []
  );

  const pollFnRaw = useMemo(() => {
    return signingId && orderRef
      ? () => {
          return dataDanica
            .kfWithdrawalPoll(signingId, orderRef)
            .then((response) => {
              // Futur includes QR code in the polling response, so we save it
              // to a ref and let the qrCodePollFn read from there to be able
              // to reuse useBankId without changes
              latestQrCode.current = response.qrCode;
              return response;
            });
        }
      : undefined;
  }, [signingId, orderRef]);
  const pollFn = useDisablePollInBackground(pollFnRaw);

  const {
    pollStatus,
    initiate,
    qrCode,
    setOpenOnOtherDevice,
    latestResponse,
    reset,
  } = useBankId({
    onComplete,
    initPollFn: dataDanica.kfWithdrawalSign,
    pollFn,
    qrCodePollFn,
    pollInterval: POLL_TIMER,
  });

  const startSign = useCallback(
    (signingId: string, userId: string) => {
      if (pollStatus === "IDLE" || pollStatus === "FAILED") {
        initiate(signingId, userId).then((response) => {
          if (response) {
            history.push(
              getUrlWithParams(
                location,
                response.orderRef,
                response.autoStartToken
              )
            );
          }
        });
      }
    },
    [history, initiate, location, pollStatus]
  );

  useEffect(() => {
    if (signingId && userId && !orderRef && pollStatus === "IDLE") {
      startSign(signingId, userId);
    }
  }, [orderRef, pollStatus, signingId, startSign, userId]);

  return (
    <BankIDStatus
      qrCode={qrCode}
      setOpenOnOtherDevice={setOpenOnOtherDevice}
      getMessages={getMessages(intl)}
      getPendingMessages={getPendingMessages(intl)}
      getFailedMessages={getFailedMessages(intl)}
      retry={() => {
        startSign(signingId, userId);
      }}
      reset={() => {
        history.push(
          getNavLink(ROUTES.SIGNERS.replace(":signingId", signingId))
        );
        reset();
      }}
      response={latestResponse}
      autoStartToken={autoStartToken}
    />
  );
};

function getMessages(intl: IntlShape) {
  return () => {
    return {
      qrInfo1: intl.formatMessage(signingMessages.qrInfo1),
      qrInfo2: intl.formatMessage(signingMessages.qrInfo2),
      qrInfo3: intl.formatMessage(signingMessages.qrInfo3),
      buttonOpen: intl.formatMessage(signingMessages.buttonOpen),
      buttonErrorHeader: intl.formatMessage(signingMessages.buttonErrorHeader),
      buttonRetry: intl.formatMessage(signingMessages.buttonRetry),
      buttonClose: intl.formatMessage(signingMessages.buttonClose),
      buttonOtherDevice: intl.formatMessage(signingMessages.buttonOtherDevice),
    };
  };
}

function getPendingMessages(intl: IntlShape) {
  return (hintCode: PendingHintCode) =>
    intl.formatMessage(signPendingMessages[hintCode]);
}

function getFailedMessages(intl: IntlShape) {
  return (hintCode: FailedHintCode) =>
    intl.formatMessage(signFailedMessages[hintCode]);
}

function getUrlWithParams(
  location: Location,
  orderRef: OrderRef | undefined,
  autoStartToken: string
) {
  const search = parse(location.search) as SearchParams;
  search.orderRef = orderRef;
  search.at = autoStartToken;

  return {
    pathname: location.pathname,
    search: stringify(search as Record<string, any>, { skipEmptyString: true }),
  };
}

function getParamsFromUrl(location: Location) {
  const search = parse(location.search) as SearchParams;
  return {
    orderRef: search.orderRef,
    autoStartToken: search.at,
  };
}
