import { useEffect, useState, useContext } from "react";
import * as React from "react";
import { Button, Card, Typography, cache } from "@lysaab/ui-2";
import { Link } from "react-router-dom";
import { OVERVIEW_PAGE_URL } from "../../../../../../pages/overview/OverviewPage";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { dataAccounts, AccountType } from "../../../../../../data/dataAccounts";
import { UserContext } from "../../../../../../context/UserContext";
import { MONTHLY_DEPOSITS_URL } from "../../../../../../pages/deposits/monthly/create/MonthlyStory";
import { INTERNAL_TRANSFER_PAGE_URL } from "../../../../../../pages/internalTransfer/InternalTransferPage";
import { DEPOSITS_OVERVIEW_URL } from "../../../../../../pages/deposits/overview/Recommendation";

export const Done: React.FC = () => {
  const { state: userState } = useContext(UserContext);
  const [vpAccountsWorth, setVpAccountsWorth] = useState<number>(0);

  useEffect(() => {
    dataAccounts
      .getAccounts()
      .then((accounts) => {
        const accumulatedWorthVPAccounts = accounts
          .filter((account) => account.type === AccountType.VP_SWE)
          .reduce((acc, account) => acc + account.worth, 0);

        setVpAccountsWorth(accumulatedWorthVPAccounts);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    // Empty and precache
    cache.clear();
    dataAccounts.getAccounts();
  }, []);

  if (!userState.legalEntityType) {
    return (
      <div className="create-account-corporation-advice">
        <h1>Kontot skapat</h1>
        <Card>
          <p>
            Vad roligt att du öppnat ett nytt konto, nu kan du börja investera!
          </p>

          <div className="bottom-nav">
            <Link
              className="lysa-ui-button button-primary button-medium block"
              to={getNavLink(OVERVIEW_PAGE_URL)}
            >
              <Typography type="label">Logga in</Typography>
            </Link>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="create-account-corporation-advice">
      <h1>Kontot skapat</h1>
      <Card>
        <p>
          Vad roligt att du öppnat ett nytt konto, nu kan du börja investera!
        </p>

        <div className="bottom-nav">
          {!!vpAccountsWorth && (
            <Button
              component={Link}
              to={getNavLink(INTERNAL_TRANSFER_PAGE_URL)}
              block
              label="Gör en intern överföring"
            />
          )}

          <Button
            component={Link}
            to={getNavLink(DEPOSITS_OVERVIEW_URL)}
            block
            label="Gör en engångsinsättning"
          />

          <Button
            component={Link}
            to={getNavLink(MONTHLY_DEPOSITS_URL)}
            block
            label="Börja månadsspara"
          />

          <Button
            component={Link}
            variant="secondary"
            to={getNavLink(OVERVIEW_PAGE_URL)}
            block
            label="Till kontoöversikten"
          />
        </div>
      </Card>
    </div>
  );
};
