import { API } from "@lysaab/ui-2";

export type TinkBankId = string & { readonly isTinkBankId: true };

export interface AvailableBank {
  id: TinkBankId;
  name: string;
  iconUrl: string;
}

export const dataTink = {
  getAllBanks: () => {
    return API.get<AvailableBank[]>("/tink/bank");
  },
};
