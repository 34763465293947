import { useCallback, useState, useEffect, useRef } from "react";
import * as React from "react";
import {
  Card,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Language,
  SwedishTin,
  DocModalLink,
} from "@lysaab/ui-2";
import { Redirect } from "react-router-dom";
import "./LysaSummary.scss";
import { useUser } from "../../../../../../context/UserContext";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { CREATE_ACCOUNT_CORPORATION_PAGE_URL } from "../CreateAccountCorporationPage";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { defineMessages, useIntl } from "react-intl";
import {
  dataDocuments,
  DocumentInformation,
} from "../../../../../../data/dataDocuments";
import { getAccountQuestions } from "../../../../../../data/dataInvestments";
import { KFAccountState, useCreateKFAccount } from "../KFAccountContext";
import {
  CreateAccountRequest,
  dataLife,
  InsuranceCreationToken,
} from "../../../../../../data/dataLife";
import { KycQuestion } from "../../../../../../data/dataKyc";
import { SummaryInfo } from "../components/SummaryInfo";

interface Props {
  next: (creationToken: InsuranceCreationToken) => void;
}

const messages = defineMessages({
  kfAccountName: {
    id: "sweden.kf.summary.account-name",
  },
  modalAnnouncement: {
    id: "sweden.kf.summary.modal-announcement",
  },
});

const KYC_VERSION = "3";

export const LysaSummary: React.FC<Props> = ({ next }) => {
  const [KFAccount] = useCreateKFAccount();
  const user = useUser();
  const [showError, setShowError] = useState<boolean>(false);
  const intl = useIntl();
  const [documentsInformation, setDocumentsInformation] =
    useState<DocumentInformation[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const creationToken = useRef(crypto.randomUUID() as InsuranceCreationToken);

  useEffect(() => {
    dataDocuments.getDocumentsInformation(true).then((information) => {
      setDocumentsInformation(information);
      setIsLoading(false);
    });
  }, []);

  const onClick = useCallback(() => {
    setShowError(false);

    const request = buildRequest(
      KFAccount,
      intl.formatMessage(messages.kfAccountName),
      creationToken.current
    );

    dataLife
      .createAccount(request)
      .then(() => {
        next(creationToken.current);
      })
      .catch(() => {
        setShowError(true);
      });
  }, [KFAccount, intl, next]);

  if (typeof KFAccount.takenRisk === "undefined") {
    return (
      <Redirect
        to={{ pathname: getNavLink(CREATE_ACCOUNT_CORPORATION_PAGE_URL) }}
      />
    );
  }

  if (!KFAccount.savingsHorizon) {
    throw new Error("KF - LysaSummary - Missing savings horizon");
  }

  if (
    isLoading ||
    typeof documentsInformation === "undefined" ||
    typeof KFAccount.investmentType === "undefined" ||
    typeof KFAccount.takenRisk === "undefined" ||
    typeof KFAccount.savingsHorizon === "undefined" ||
    typeof KFAccount.identificationNumber === "undefined" ||
    typeof user.name === "undefined"
  ) {
    return <Spinner />;
  }

  return (
    <div className="create-account-corporation-lysa-summary">
      <h1>
        <TranslatedText id="sweden.kf.summary.header" />
      </h1>
      <SummaryInfo
        investmentType={KFAccount.investmentType}
        takenRisk={KFAccount.takenRisk}
        savingsHorizon={KFAccount.savingsHorizon}
        insured={KFAccount.identificationNumber}
      />

      {KFAccount.ongoing ? null : (
        <Card>
          <p>
            <TranslatedText
              id="sweden.kf.summary.privacy-note"
              values={{
                privacyNote: (linkText) => {
                  return (
                    <DocModalLink
                      document={"legal/se/sv/personuppgiftspolicy.md"}
                      modalAnnouncement={intl.formatMessage(
                        messages.modalAnnouncement
                      )}
                      key="element-key-sa"
                    >
                      {linkText}
                    </DocModalLink>
                  );
                },
              }}
            />
          </p>
        </Card>
      )}

      {showError ? (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <div>
            <TranslatedText id="sweden.kf.summary.error" />
          </div>
        </Snackbar>
      ) : null}

      <Button
        block
        onClick={onClick}
        label={
          showError ? (
            <TranslatedText id="sweden.kf.summary.retry" />
          ) : (
            <TranslatedText id="sweden.kf.summary.sign" />
          )
        }
      />
    </div>
  );
};

function buildRequest(
  KFAccount: KFAccountState,
  accountName: string,
  creationToken: InsuranceCreationToken
): CreateAccountRequest {
  if (typeof KFAccount.takenRisk === "undefined") {
    throw new Error("KF - LysaSummary - Missing taken risk");
  }

  if (typeof KFAccount.investmentType === "undefined") {
    throw new Error("KF - LysaSummary - Missing investment type");
  }

  if (typeof KFAccount.identificationNumber === "undefined") {
    throw new Error("KF - LysaSummary - Missing identification number");
  }

  if (typeof KFAccount.depositInterval === "undefined") {
    throw new Error("KF - LysaSummary - Missing deposit interval");
  }

  if (typeof KFAccount.withdrawalInterval === "undefined") {
    throw new Error("KF - LysaSummary - Missing withdrawal interval");
  }

  const accountQuestions = getAccountQuestions(KFAccount);

  return {
    takenRisk: KFAccount.takenRisk,
    investmentType: KFAccount.investmentType,
    insured: {
      identificationNumber: new SwedishTin(
        KFAccount.identificationNumber
      ).getNormalizedTin(),
    },
    lysaKyc: {
      version: KYC_VERSION,
      questionAnswers: [
        {
          question: KycQuestion.PURPOSE,
          answers: KFAccount.purpose,
        },
        {
          question: KycQuestion.DEPOSIT_INTERVAL,
          answers: [KFAccount.depositInterval],
        },
        {
          question: KycQuestion.WITHDRAWAL_INTERVAL,
          answers: [KFAccount.withdrawalInterval],
        },
      ],
    },
    accountName: accountName,
    creationToken: creationToken,
    language: Language.SWEDISH,
    ...accountQuestions,
  };
}
