import { FunctionComponent, useEffect, useState } from "react";
import {
  Button,
  Form,
  SNACKBAR_TYPES,
  Snackbar,
  Typography,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import {
  PensionMoveSigningOptions,
  dataLifePensionMove,
} from "../../../../../data/dataLifePensionMove";
import { useTransfer } from "../TransferContext";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { MailingInstructions } from "../components/mailingInstructions/MailingInstructions";
import { MoveCard } from "../components/moveCard/MoveCard";

import "./SnailMail.scss";

interface Props {
  next: (caseId: string) => void;
}

type Status = "idle" | "need-action" | "action-taken";

export const SnailMail: FunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const [transfer, setTransfer] = useTransfer();
  const location = useLocation();
  const [status, setStatus] = useState<Status>("idle");

  const { pathname } = location;
  const pathnameParts = pathname.split("/");
  const caseId = pathnameParts[pathnameParts.length - 1];

  /**
   * We use an effect to preserve the user's state across refreshes.
   * In this context, 'move' refers to the entire transfer request.
   */

  useEffect(() => {
    if (caseId) {
      dataLifePensionMove.getMove(caseId).then((transfer) => {
        setTransfer(transfer);
      });
    }
  }, [caseId, setTransfer]);

  const moves = transfer.moves.filter(
    (move) =>
      move.signing === PensionMoveSigningOptions.MANUAL ||
      move.signing === PensionMoveSigningOptions.SCRIVE
  );

  return (
    <article className="transfer-pension-snail-mail">
      <Form
        onSubmit={() => {
          if (status === "action-taken") {
            next(transfer.caseId);
          } else {
            setStatus("need-action");
          }
        }}
      >
        <Typography type="h2">
          <TranslatedText id={"sweden.transfer-pension.snail-mail.header"} />
        </Typography>
        <Typography type="body">
          <TranslatedText id={"sweden.transfer-pension.snail-mail.ingress"} />
        </Typography>
        <section className="pensions">
          {moves.map((move) => {
            return (
              <MoveCard
                key={move.id}
                insuranceCompany={move.institute}
                insuranceNumber={move.insuranceNumber}
                currentWorth={move.currentWorth}
                insuranceHolderName={move.employer}
                insuranceHolderTin={move.employerTin}
                type={move.type}
              />
            );
          })}
        </section>

        <MailingInstructions
          caseId={caseId}
          onActionTaken={() => {
            setStatus("action-taken");
          }}
        />

        {status === "need-action" && (
          <Typography type="body" className="need-action">
            <TranslatedText
              id={"sweden.transfer-pension.snail-mail.required.notice"}
            />
          </Typography>
        )}
        <Snackbar type={SNACKBAR_TYPES.INFO} icon>
          <div style={{ textAlign: "left" }}>
            <TranslatedText id="sweden.transfer-pension.snail-mail.snackbar.info" />
          </div>
        </Snackbar>
        <Button
          block
          variant="primary"
          type="submit"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.snail-mail.sign.button",
          })}
        />
      </Form>
    </article>
  );
};
