import { FunctionComponent } from "react";

import { useIntl } from "react-intl";
import { useTransfer } from "../TransferContext";
import { Button, NewIcon, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./Sign.scss";
import { Ingress } from "../components/ingress/Ingress";

interface Props {
  next: (caseId: string) => void;
}

export const Sign: FunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const [transfer] = useTransfer();

  return (
    <article className="transfer-pension-sign">
      <header>
        <NewIcon.Signature
          size={96}
          primaryColor="var(--lysa-icon-secondary)"
          className="icon"
        />
        <Typography type="display-small">
          <TranslatedText id={"sweden.transfer-pension.sign.header"} />
        </Typography>
        <Ingress>
          <TranslatedText id={"sweden.transfer-pension.sign.ingress"} />
        </Ingress>
      </header>
      <Button
        className="next"
        block
        variant="primary"
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.sign.button",
        })}
        onClick={() => {
          next(transfer.caseId);
        }}
      />
    </article>
  );
};
