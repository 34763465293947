import {
  useState,
  useEffect,
  FunctionComponent,
  useRef,
  useContext,
} from "react";
import {
  Form,
  TextInput,
  Select,
  RequiredValidator,
  RadioGroup,
  MoneyInput,
  Button,
  Card,
  LysaFormRef,
  MinValidator,
  Alternative,
  Snackbar,
  SNACKBAR_TYPES,
  Checkbox,
} from "@lysaab/ui-2";
import { IskTransferContext, TypeOfTransfer } from "./IskTransferContext";
import { dataIsk } from "../../../../data/dataIsk";
import { dataAccounts, InvestmentAccount } from "../../../../data/dataAccounts";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { TranslatedText } from "../../../../components/TranslatedText";
import { handelbankenOffices } from "./HandelbankenOffices";

interface MessageWithId {
  id: string;
}

const typeAltMessages = defineMessages<
  TypeOfTransfer,
  MessageWithId,
  Record<TypeOfTransfer, MessageWithId>
>({
  [TypeOfTransfer.FULL]: {
    id: "sweden.isk.move.transfer.full",
  },
  [TypeOfTransfer.PARTIAL]: {
    id: "sweden.isk.move.transfer.partial",
  },
});

const messages = defineMessages({
  instituteLabel: {
    id: "sweden.isk.move.transfer.institute.label",
  },
  institutePlaceholer: {
    id: "sweden.isk.move.transfer.institute.placeholder",
  },
  instituteRequired: {
    id: "sweden.isk.move.transfer.institute.required",
  },
  officeLabel: {
    id: "sweden.isk.move.transfer.office.label",
  },
  officePlaceholer: {
    id: "sweden.isk.move.transfer.office.placeholder",
  },
  officeRequired: {
    id: "sweden.isk.move.transfer.office.required",
  },
  iskAccountLabel: {
    id: "sweden.isk.move.transfer.isk.label",
  },
  iskAccountRequired: {
    id: "sweden.isk.move.transfer.isk.required",
  },
  lysaLabel: {
    id: "sweden.isk.move.transfer.lysa.label",
  },
  lysaPlaceholer: {
    id: "sweden.isk.move.transfer.lysa.placeholder",
  },
  lysaRequired: {
    id: "sweden.isk.move.transfer.lysa.required",
  },
  typeLabel: {
    id: "sweden.isk.move.transfer.type.label",
  },
  typeRequired: {
    id: "sweden.isk.move.transfer.type.required",
  },
  amountMin: {
    id: "sweden.isk.move.transfer.amount.min",
  },
  amountRequired: {
    id: "sweden.isk.move.transfer.amount.required",
  },
  closeAccountLabel: {
    id: "sweden.isk.move.transfer.close-account.label",
  },
});

interface Props {
  next: () => void;
}

const handelbankenOfficeAlternatives = handelbankenOffices.map((office) => ({
  text: office.label,
  value: office.branchId,
}));

export const IskTransfer: FunctionComponent<Props> = ({ next }) => {
  const iskTransferContext = useContext(IskTransferContext);
  const [accounts, setAccounts] = useState<InvestmentAccount[]>([]);
  const [institutions, setInstitutions] = useState<Alternative<string>[]>([]);
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();

  const typeAlternatives = [
    {
      text: intl.formatMessage(typeAltMessages[TypeOfTransfer.FULL]),
      value: TypeOfTransfer.FULL,
    },
    {
      text: intl.formatMessage(typeAltMessages[TypeOfTransfer.PARTIAL]),
      value: TypeOfTransfer.PARTIAL,
    },
  ];

  useEffect(() => {
    dataAccounts.getAccounts().then((accounts) => setAccounts(accounts));
    dataIsk.getInstitutions().then((resp) => setInstitutions(resp));
  }, []);

  return (
    <Form
      onSubmit={() => {
        if (!formRef.current?.isValid) {
          return;
        }
        next();
      }}
      lysaFormRef={formRef}
    >
      <h2>
        <FormattedMessage id="sweden.isk.move.transfer.header" />
      </h2>
      <Card>
        <Select
          label={intl.formatMessage(messages.instituteLabel)}
          placeholder={intl.formatMessage(messages.instituteLabel)}
          alternatives={institutions}
          value={iskTransferContext.state.institution}
          onChange={(inst) =>
            iskTransferContext.setState({ institution: inst })
          }
          validators={[
            new RequiredValidator(
              intl.formatMessage(messages.instituteRequired)
            ),
          ]}
        />
        {iskTransferContext.state.institution?.value === "SHB" && (
          <Select
            label={intl.formatMessage(messages.officeLabel)}
            placeholder={intl.formatMessage(messages.officePlaceholer)}
            alternatives={handelbankenOfficeAlternatives}
            value={handelbankenOfficeAlternatives.find(
              (office) => office.value === iskTransferContext.state.office
            )}
            onChange={(office) =>
              iskTransferContext.setState({ office: office.value })
            }
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.officeRequired)
              ),
            ]}
          />
        )}
        <TextInput
          label={intl.formatMessage(messages.iskAccountLabel)}
          value={iskTransferContext.state.accountNumber ?? ""}
          onChange={(accountNr) =>
            iskTransferContext.setState({ accountNumber: accountNr })
          }
          validators={[
            new RequiredValidator(
              intl.formatMessage(messages.iskAccountRequired)
            ),
          ]}
        />
        <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
          <TranslatedText id="sweden.isk.move.transfer.disclaimer" />
        </Snackbar>
      </Card>
      <h2>
        <FormattedMessage id="sweden.isk.move.transfer.lysa.header" />
      </h2>
      <Card>
        <Select
          label={intl.formatMessage(messages.lysaLabel)}
          placeholder={intl.formatMessage(messages.lysaPlaceholer)}
          alternatives={accounts.map((account) => {
            return {
              text: account.name,
              value: account.accountId,
            };
          })}
          onChange={(account) =>
            iskTransferContext.setState({ account: account })
          }
          value={iskTransferContext.state.account}
          validators={[
            new RequiredValidator(intl.formatMessage(messages.lysaRequired)),
          ]}
        />
      </Card>
      <h2>
        <FormattedMessage id="sweden.isk.move.transfer.type.header" />
      </h2>
      <Card>
        <RadioGroup
          header={intl.formatMessage(messages.typeLabel)}
          alternatives={typeAlternatives}
          onChange={(type) => {
            if (type.value === TypeOfTransfer.PARTIAL) {
              iskTransferContext.setState({ type: type, closeAccount: false });
            } else {
              iskTransferContext.setState({ type: type });
            }
          }}
          validators={[
            new RequiredValidator(intl.formatMessage(messages.typeRequired)),
          ]}
          value={iskTransferContext.state.type}
        />
        {iskTransferContext.state.type &&
          iskTransferContext.state.type.value === TypeOfTransfer.FULL && (
            <>
              {iskTransferContext.state.institution?.value === "AVA" ? (
                <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
                  <FormattedMessage id="sweden.isk.move.transfer.full.avanza.warning" />
                </Snackbar>
              ) : (
                <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
                  <FormattedMessage id="sweden.isk.move.transfer.full.warning" />
                </Snackbar>
              )}
            </>
          )}
        {iskTransferContext.state.type &&
          iskTransferContext.state.type.value === TypeOfTransfer.PARTIAL && (
            <div>
              <label>
                <FormattedMessage id="sweden.isk.move.transfer.amount.label" />
                <MoneyInput
                  validators={[
                    new RequiredValidator(
                      intl.formatMessage(messages.amountRequired)
                    ),
                    new MinValidator(
                      5000,
                      intl.formatMessage(messages.amountMin)
                    ),
                  ]}
                  value={iskTransferContext.state.amount}
                  currency="kr"
                  onChange={(amount) => iskTransferContext.setState({ amount })}
                />
              </label>
              <p>
                <FormattedMessage id="sweden.isk.move.transfer.amount.disclaimer" />
              </p>
            </div>
          )}
      </Card>
      {iskTransferContext.state.type &&
        iskTransferContext.state.type.value === TypeOfTransfer.FULL && (
          <Checkbox
            alternative={{
              text: intl.formatMessage(messages.closeAccountLabel),
              value: true,
            }}
            checked={iskTransferContext.state.closeAccount}
            onChange={() =>
              iskTransferContext.setState({
                closeAccount: !iskTransferContext.state.closeAccount,
              })
            }
          />
        )}

      <Button
        block
        type="submit"
        label={<FormattedMessage id="sweden.isk.move.transfer.next" />}
      />
    </Form>
  );
};
