import { VoidFunctionComponent, useState } from "react";
import { CircleButton } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { SavingsAccountDataProps } from "../../OverviewCard";
import { SavingsRelatedInformationModal } from "../informationModal/InformationModal";
import {
  FeatureDomain,
  SubDomain,
  dataCustomerTrackingService,
} from "../../../../../../../data/dataCustomerTracking";
import { Interval } from "../../../../../../../data/dataPerformance";
import { SavingsSummaryNumber } from "./components/SavingsSummaryNumber";
import { HolidayAnimations } from "../../../../../../overview/holidays/valentines/HolidayAnimations";
import { summaryHeaderIntervalMessages } from "../../../../../../../components/IntervalMessages";

import "./SavingsSummaryHeader.scss";

interface Props {
  savingsAccountData: SavingsAccountDataProps | undefined;
  interval: Interval;
}

export const SavingsSummaryHeader: VoidFunctionComponent<Props> = ({
  savingsAccountData,
  interval,
}) => {
  const [showInformationModal, setShowInformationModal] = useState(false);

  const intl = useIntl();

  return (
    <>
      <div className="savings-summary-header">
        <div className="savings-summary-header__animations">
          <HolidayAnimations />
        </div>

        <div className="savings-summary-header__information-button">
          <CircleButton
            icon="Information"
            onClick={() => {
              dataCustomerTrackingService.postEvent({
                domain: FeatureDomain.SAVINGS_ACCOUNT,
                eventName: "clickedAccountValueHelpButton",
                subDomain: SubDomain.ACCOUNT_DETAILS,
              });
              setShowInformationModal(true);
            }}
          />
        </div>

        <div className="savings-summary-header__numbers">
          <SavingsSummaryNumber
            label={intl.formatMessage({
              id: "savingsAccountPage.overviewCard.header",
            })}
            amount={savingsAccountData?.balance}
            large
          />

          <SavingsSummaryNumber
            label={intl.formatMessage(summaryHeaderIntervalMessages[interval])}
            amount={savingsAccountData?.totalEarnings}
            showSign
          />

          <SavingsSummaryNumber
            label={intl.formatMessage({
              id: "savingsAccountPage.overviewCard.available",
            })}
            amount={savingsAccountData?.availableForWithdrawal}
          />
        </div>
      </div>

      <SavingsRelatedInformationModal
        showModal={showInformationModal}
        onClose={() => setShowInformationModal(false)}
      />
    </>
  );
};
