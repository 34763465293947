import { FunctionComponent, useEffect, useRef } from "react";

import {
  Button,
  Form,
  LysaFormRef,
  MinLengthValidator,
  OrgValidator,
  RadioGroupCard,
  RequiredValidator,
  SNACKBAR_TYPES,
  Select,
  Snackbar,
  TextInput,
  Typography,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { AccountType } from "../../../../../data/dataAccounts";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { useTransfer } from "../TransferContext";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { InvalidCompanyValidator } from "../components/validators/InvalidCompanyValidator";

import "./InsuranceInformation.scss";
import { Institute } from "../../../../../data/dataLifePensionMove";
interface Props {
  next: () => void;
  navigateToAdditionalMove: () => void;
  reset: () => void;
}

enum SubmitType {
  ADD = "ADD",
  NEXT = "NEXT",
}

export const institutePrettyNames: Record<Institute, string> = {
  [Institute.AVANZA]: "Avanza",
  [Institute.FOLKSAM]: "Folksam",
  [Institute.FUTUR]: "Futur",
  [Institute.HANDELSBANKEN]: "Handelsbanken",
  [Institute.LANSFORSAKRINGAR]: "Länsförsäkringar",
  [Institute.NORDEA]: "Nordea",
  [Institute.NORDNET]: "Nordnet",
  [Institute.MOVESTIC]: "Movestic",
  [Institute.SEB]: "SEB",
  [Institute.SKANDIA]: "Skandia",
  [Institute.SPP]: "SPP",
  [Institute.SWEDBANK]: "Swedbank",
};

const instituteAlternatives = Object.values(Institute)
  .filter((value) => value !== Institute.SWEDBANK) // Swedbank was only part of test phase
  .map((value) => ({
    text: institutePrettyNames[value],
    value,
  }));

export const InsuranceInformation: FunctionComponent<Props> = ({
  next,
  navigateToAdditionalMove,
  reset,
}) => {
  const intl = useIntl();
  const [transfer, setTransfer] = useTransfer();
  const formRef = useRef<LysaFormRef>();
  const location = useLocation();
  const pathname = location.pathname;
  const segments = pathname.split("/");
  const movesNumber = Number(segments[segments.length - 1]) | 0;
  const moves = transfer.moves;
  const isLast = movesNumber === moves.length - 1;
  const history = useHistory();

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (
      transfer?.moves.length > 1 &&
      typeof transfer?.moves[0]?.institute === "undefined"
    ) {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  /**
   * Ensure the cancel move functionality works. When a move is cancelled, it is removed from the state,
   * causing transfer.moves[movesNumber] to become undefined. In this case, render null and redirect the user.
   */

  if (transfer.moves[movesNumber] === undefined) {
    return null;
  }

  return (
    <article className="transfer-pension-insurance-info">
      <Form
        lysaFormRef={formRef}
        onSubmit={(
          event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>
        ) => {
          const realEvent = event.nativeEvent;
          const eventType = realEvent.submitter?.getAttribute("name");

          if (!formRef.current?.isValid) {
            return;
          }

          /**
           * TODO_PEN: Figure out how to do this later.
           */

          // if (transfer.moves[movesNumber].type === AccountType.LYSA_TJP) {
          //   const orgNr = transfer.moves[movesNumber].employerTin;
          //   if (orgNr) {
          //     dataLifePensionMove.getCompanyName(orgNr).then((response) => {
          //       if (response && response.status === "OK") {
          //         const currentMoves = transfer.moves;
          //         const nextMoves = currentMoves.map((move, idx) => {
          //           if (idx === movesNumber) {
          //             return {
          //               ...move,
          //               employer: response.name,
          //             };
          //           } else {
          //             return move;
          //           }
          //         });
          //         setTransfer({ moves: nextMoves });
          //       }
          //     });
          //   }
          // }

          if (eventType === SubmitType.ADD) {
            setTransfer({
              moves: [...transfer.moves, {}],
            });
            navigateToAdditionalMove();
            return;
          }

          next();
        }}
      >
        <Typography type="h2" className="heading">
          <TranslatedText id="sweden.transfer-pension.insurance-info.header" />
        </Typography>
        <RadioGroupCard
          alternatives={[
            {
              header: intl.formatMessage({
                id: "sweden.transfer-pension.pension-type.tjp.header",
              }),
              description: intl.formatMessage({
                id: "sweden.transfer-pension.pension-type.tjp.description",
              }),
              value: AccountType.LYSA_TJP,
            },
            {
              header: intl.formatMessage({
                id: "sweden.transfer-pension.pension-type.ppf.header",
              }),
              description: intl.formatMessage({
                id: "sweden.transfer-pension.pension-type.ppf.description",
              }),
              value: AccountType.LYSA_PPF,
            },
          ]}
          legend={
            <label className="radio-group-card-label">
              <TranslatedText id="sweden.transfer-pension.pension-type.label" />
            </label>
          }
          onChange={(newValue) => {
            const currentMoves = transfer.moves;
            const nextMoves = currentMoves.map((move, idx) => {
              if (idx === movesNumber) {
                return { ...move, type: newValue };
              } else {
                return move;
              }
            });
            setTransfer({ moves: nextMoves });
          }}
          validators={[
            new RequiredValidator(
              intl.formatMessage({
                id: "sweden.transfer-pension.pension-type.required",
              })
            ),
          ]}
          value={transfer.moves[movesNumber].type}
        />
        <Select
          label={intl.formatMessage({
            id: "sweden.transfer-pension.insurance-info.institute-select.label",
          })}
          placeholder={intl.formatMessage({
            id: "sweden.transfer-pension.insurance-info.institute-select.placeholder",
          })}
          validators={[
            new RequiredValidator(
              intl.formatMessage({
                id: "sweden.transfer-pension.insurance-info.institute-select.error",
              })
            ),
          ]}
          alternatives={instituteAlternatives}
          value={instituteAlternatives.find(
            (alternative) =>
              alternative.value === transfer.moves[movesNumber].institute
          )}
          onChange={(newValue) => {
            const currentMoves = transfer.moves;
            const nextMoves = currentMoves.map((move, idx) => {
              if (idx === movesNumber) {
                return { ...move, institute: newValue.value };
              } else {
                return move;
              }
            });
            setTransfer({ moves: nextMoves });
          }}
        />
        <TextInput
          label={intl.formatMessage({
            id: "sweden.transfer-pension.insurance-info.insurance-number-input.label",
          })}
          placeholder={intl.formatMessage({
            id: "sweden.transfer-pension.insurance-info.insurance-number-input.placeholder",
          })}
          validators={[
            new RequiredValidator(
              intl.formatMessage({
                id: "sweden.transfer-pension.insurance-info.insurance-number-input.required.error",
              })
            ),
            new MinLengthValidator(
              5,
              intl.formatMessage({
                id: "sweden.transfer-pension.insurance-info.insurance-number-input.length.error",
              })
            ),
          ]}
          value={transfer.moves[movesNumber].insuranceNumber ?? ""}
          onChange={(newValue) => {
            const currentMoves = transfer.moves;
            const nextMoves = currentMoves.map((move, idx) => {
              if (idx === movesNumber) {
                return { ...move, insuranceNumber: newValue };
              } else {
                return move;
              }
            });
            setTransfer({ moves: nextMoves });
          }}
          showSuccessState
        />
        {(transfer.moves[movesNumber].type === undefined ||
          transfer.moves[movesNumber].type === AccountType.LYSA_TJP) && (
          <TextInput
            label={intl.formatMessage({
              id: "sweden.transfer-pension.employer-info.org-number-input.label",
            })}
            placeholder={intl.formatMessage({
              id: "sweden.transfer-pension.employer-info.org-number-input.placeholder",
            })}
            validators={[
              new RequiredValidator(
                intl.formatMessage({
                  id: "sweden.transfer-pension.employer-info.org-number-input.required",
                })
              ),
              new OrgValidator(
                intl.formatMessage({
                  id: "sweden.transfer-pension.employer-info.org-number-input.error",
                })
              ),
              new InvalidCompanyValidator(
                intl.formatMessage({
                  id: "sweden.transfer-pension.employer-info.org-number-input.corporation.error",
                })
              ),
            ]}
            value={transfer.moves[movesNumber].employerTin ?? ""}
            onChange={(newValue) => {
              const currentMoves = transfer.moves;
              const nextMoves = currentMoves.map((move, idx) => {
                if (idx === movesNumber) {
                  return { ...move, employerTin: newValue };
                } else {
                  return move;
                }
              });
              setTransfer({ moves: nextMoves });
            }}
            showSuccessState
          />
        )}
        <Snackbar type={SNACKBAR_TYPES.INFO} icon>
          <div style={{ textAlign: "left" }}>
            <TranslatedText
              id="sweden.transfer-pension.insurance-info.snackbar"
              values={{
                link: (text) => {
                  return (
                    <a
                      className="snackbar-link"
                      target="_blank"
                      href="https://www.minpension.se/"
                      rel="noreferrer"
                    >
                      <strong>{text}</strong>
                    </a>
                  );
                },
              }}
            />
          </div>
        </Snackbar>
        {isLast && (
          <Button
            block
            type="submit"
            name={SubmitType.ADD}
            variant="secondary"
            label={intl.formatMessage({
              id: "sweden.transfer-pension.insurance-info.add-button",
            })}
            icon="Plus"
          />
        )}
        <Button
          block
          type="submit"
          name={SubmitType.NEXT}
          variant="primary"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.insurance-info.next-button",
          })}
        />
        <Button
          block
          type="button"
          name="remove"
          variant="outlined"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.insurance-info.remove",
          })}
          onClick={() => {
            const currentMoves = transfer.moves;
            // TODO_PEN: This removes the _last_ move, not the current one we are on
            // This is a bug when adding two moves, going back and then pressing this
            const nextMoves = currentMoves.slice(0, -1);
            if (nextMoves.length === 0) {
              setTransfer({ moves: [{}] });
              reset();
            } else {
              setTransfer({ moves: nextMoves });
              history.goBack();
            }
          }}
        />
      </Form>
    </article>
  );
};
